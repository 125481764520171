import styled from "styled-components";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";

export const LoadingGenderSelectContainer = styled(FlexWrap)`
  width: 100%;
  max-width: 360px;
  margin: 24px auto;
  padding: 0 16px;

  ${props => props.isPro && `
      position: absolute;
      bottom: 35px;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
  `}

  p {
    font-size: 9px;
    line-height: 1;
    color: rgba(34, 34, 34, .7);
  }
`;

export const LoadingGenderButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% / 3) - 2px);
  border-radius: 8px;
  color: ${props => props.active ? "#fff" : "rgb(17, 17, 17)"};
  background: ${props => props.active ? "rgb(103, 172, 250)" : "rgb(180, 215, 254)"};
  font-size: 12px;
  line-height: 2;

  &:disabled {
    color: rgba(0, 0, 0, 0.7);
    background: rgba(180, 215, 254, 0.7);
  }
`;
