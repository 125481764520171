import {ApiResponseError} from "./api";
import i18n from "../i18n";
import md5 from "md5";
import {promisifyImage} from "./image";
import * as api from "./api";

const IMAGE_MAX_SIDE_SIZE = 1200;

export default (file, ignore = []) => {
  const allowedTypes = [
    "image/jpeg",
    "image/png",
  ];

  if (!allowedTypes.includes(file.type.toLowerCase())) {
    return Promise.reject(new ApiResponseError({
      error_code: 415,
      error_message: i18n.t("error__api_code_415"),
    }));
  }

  let fileHash = null;

  return promisifyFileReader(file)
    .then((binary) => {
      fileHash = md5(binary);

      if (ignore.indexOf(fileHash) > -1) {
        return undefined;
      }

      return Promise.resolve(binary)
        .then(promisifyImage)
        .then(prepareImage)
        .then((result) => {
          return api.tempImagesUploadFile(result, file.type.replace("image/"));
        })
        .then((fileUrl) => {
          return {
            url: fileUrl,
            rect: [],
            rotation: 0,
            flip: 0
          };
        });
    })
}

function promisifyFileReader(input) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => resolve(e.target.result);
    fileReader.onerror = (e) => reject(e.target.error);
    fileReader.readAsDataURL(input);
  });
}

function prepareImage(image) {
  const shouldResize = image.width > IMAGE_MAX_SIDE_SIZE || image.height > IMAGE_MAX_SIDE_SIZE;

  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext("2d");

  if (shouldResize) {
    const ratio = (image.width > image.height)
      ? IMAGE_MAX_SIDE_SIZE / image.width
      : IMAGE_MAX_SIDE_SIZE / image.height;

    canvas.width = Math.round(image.width * ratio);
    canvas.height = Math.round(image.height * ratio);

    // eslint-disable-next-line valid-typeof
    if (typeof ctx.imageSmoothingQuality !== undefined) {
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    } else {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      // todo step by step resize to smooth
    }
  } else {
    ctx.drawImage(image, 0, 0);
  }

  return new Promise((resolve) => canvas.toBlob(resolve, "image/jpeg", 100));
}
