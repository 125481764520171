import React from "react";
import {ProcessingLoaderAvatarStyled, ProcessingLoaderProgressBarStyled, ProcessingLoaderStyled, ProcessingLoaderTextStyled} from "./ProcessingLoader.style";
import {ProcessingLoaderIndicatorStyled} from "./ProcessingLoader.style";
import i18n from "../../i18n";
import Creative from "../../photolab/Creative";

const LOADING_TEXT_INTERVAL = 3000;
const maxProgressPercent = 0.99;

export default class ProcessingLoader extends React.Component {
    constructor(props) {
        super(props);

        this.texts = this.props.texts;
        this.state = {
            height: 0,
            textIndex: 0,
            imageIndex: 0,
            progressPercent: 0
        }

        this.processingTookSecondsApprox = 20;
        this.updateTextIndexTimer = null;
        this.progressUpdateTimer = null;
    }

    componentDidMount() {
        this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
        this.handleStartProgressUpdate();
    }

    componentWillUnmount() {
        this.handleStopProgressUpdate();
        clearInterval(this.updateTextIndexTimer);
    }

    componentDidUpdate() {
        if (!this.props.creative) {
            return;
        }

        if (this.props.creative.isFinished) {
            this.handleStopProgressUpdate();
        } else if (this.props.creative.isStarted) {
            this.handleStartProgressUpdate();
        }
    }

    handleStartProgressUpdate = () => {
        cancelAnimationFrame(this.progressUpdateTimer);
        this.progressUpdateTimer = requestAnimationFrame(this.handleProgressUpdate);
    };

    handleStopProgressUpdate = () => {
        cancelAnimationFrame(this.progressUpdateTimer);
    }

    handleProgressUpdate = () => {
        if (!this.props.creative) {
            return;
        }

        const secondsPassedFromStart = (Date.now() - this.props.creative.getExtra(Creative.EXTRA_STARTED_AT)) / 1000;

        if (secondsPassedFromStart <= 10) {
            this.processingTookSecondsApprox = 20;
        } else {
            this.processingTookSecondsApprox = Math.min(150, this.processingTookSecondsApprox + 0.015);
        }

        let progressPercent = (secondsPassedFromStart / this.processingTookSecondsApprox) * maxProgressPercent;

        this.setState({progressPercent: Math.min(progressPercent, maxProgressPercent)});

        if (progressPercent >= maxProgressPercent || this.props.creative.isFinished) {
            this.handleStopProgressUpdate();
        }
    };

    updateTextIndex = () => {
        if (this.state.textIndex === this.texts.length - 1) {
            this.setState({textIndex: 0});
        } else {
            this.setState({textIndex: this.state.textIndex + 1});
        }

        const nextImageIndex = this.state.imageIndex + 1;
        if (nextImageIndex === this.props.imagesUrls.length) {
            this.setState({imageIndex: 0});
        } else {
            this.setState({imageIndex: nextImageIndex});
        }
    }

    render() {
        if (this.props.hidden) {
            return <React.Fragment />;
        }

        const circleRadius = window.innerHeight > 600 ? 86.5 : 59;
        const strokeDasharray = 2 * Math.PI * circleRadius;
        const calculatePercent = strokeDasharray * (1 - this.state.progressPercent);

        return (
            <ProcessingLoaderStyled isPro={window.clientConfig.isPro}>
                <ProcessingLoaderProgressBarStyled isPro={window.clientConfig.isPro}>
                    <ProcessingLoaderIndicatorStyled isPro={window.clientConfig.isPro}>
                        <circle stroke={this.props.stroke}></circle>
                        <circle strokeDashoffset={calculatePercent} strokeDasharray={strokeDasharray}></circle>
                    </ProcessingLoaderIndicatorStyled>
                    {(this.props.blur || this.props.imagesUrls.isNotEmpty()) && <ProcessingLoaderAvatarStyled
                        blur={this.props.blur}
                        src={this.state.imageIndex < this.props.imagesUrls.length ? this.props.imagesUrls[this.state.imageIndex] : this.props.imagesUrls[0]}
                        alt=""
                    />}
                </ProcessingLoaderProgressBarStyled>
                {window.clientConfig.isPro && <ProcessingLoaderTextStyled dangerouslySetInnerHTML={{__html: i18n.t(this.texts[this.state.textIndex]) || ""}} />}
            </ProcessingLoaderStyled>
        )
    }
}
