import styled from "styled-components";

export const BannerViewContainer = styled.div`
  background-color: rgb(255, 98, 165);
  border-radius: 40px;
  box-sizing: border-box;
  max-width: 360px;
  width: 90%;
  padding: 40px 24px 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props => props.full && `
    max-width: 100%;
    width: 100%;

    .subscribe-banner-view-media-container {
      width: 100%;
    }
  `}

  @media (max-height: 680px) {
    padding: 24px 16px;
  }


  @media (max-height: 645px) {
    padding: 16px;
  }

  h3 {
    font-size: 24px;
    line-height: 1.34;
    color: rgb(254, 254, 225);
    text-align: center;
    margin-bottom: 24px;

    span {
      color: rgb(255, 240, 102);
    }

    @media (max-height: 645px) {
      font-size: 18px;
      line-height: 1.3;
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 18px;
    line-height: 24px;
    color: rgb(254, 254, 225);
    text-align: center;
    margin-bottom: 16px;

    @media (max-height: 645px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  button {
    width: 180px;
    height: 48px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 240, 102);
    margin: 0 auto;

    svg {
      fill: #000;
      width: 28px;
      margin-left: 16px;
      animation: shake 800ms infinite ease-out;
    }
  }

  .subscribe-banner-view-media-container {
    width: 100%;
    
    &.subscribe-banner-view-media-container_animation {
      height: 160px;
      margin-bottom: 16px;
      & > div {
        height: 100%;
      }

      @media (max-height: 645px) {
        height: 120px;
      }

      @media (max-height: 510px) {
        height: 80px;
      }
    }

    &.subscribe-banner-view-media-container_image {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;

      svg,
      img {
        height: 100%;
        object-fit: contain;
      }

      @media (max-height: 645px) {
        margin-bottom: 16px;
        height: 50px;
      }

      @media (max-height: 460px) {
        margin-bottom: 12px;
        height: 40px;
      }
    }
  }

  @keyframes shake {
    0% {
      transform: translateX(-5px);
    }

    50% {
      transform: translateX(5px);
    }

    100% {
      transform: translateX(-5px);
    }
  }
`;
