import {generatePath} from "react-router";
import routes from "../routes";
import clientStorage from "./client-storage";
import * as api from "./api";
import processingManager from "../photolab/ProcessingManager";

export function goToProcessing(history, files) {
  if (window.clientConfig.isWeb) {
    processingManager.clear();
    history.replace({
      pathname: generatePath(routes.PROCESSING),
      state: {files},
    });

    return;
  }

  clientStorage.setLatestSelectedImages(files);

  const processingUrl = new URL(window.location.origin);

  processingUrl.pathname = generatePath(routes.PROCESSING, {hash: Date.now()});

  Object.keys(window.clientConfig.webviewParams).forEach((key) => {
    processingUrl.searchParams.append(key, window.clientConfig.webviewParams[key]);
  });

  api.getBuildInfo()
    .then((result) => {
      processingManager.clear();
      if (parseInt(window.appConfig.build.version) < parseInt(result.version)) {
        window.location.replace(processingUrl.toString());
        // hitEvent(hits.FORCE_REDIRECT_PROCESSING);
      } else {
        history.replace({
          pathname: generatePath(routes.PROCESSING),
          state: {files},
        });
      }
    })
    .catch((err) => {
      processingManager.clear();
      window.location.replace(processingUrl.toString());
      // hitEvent(hits.FORCE_REDIRECT_PROCESSING);
    });
}
