import styled from "styled-components";

export const MainTitleStyled = styled.h1`
  font-family: "Barbie";
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #222;
  letter-spacing: -0.4px;
  font-style: italic;
  margin-bottom: 16px;

  @media (max-height: 640px) {
    font-size: 20px;
    line-height: 28px;
  }
`;
