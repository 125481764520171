import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";
import {webviewAnalyticsEvent, webviewOpenBrowser} from "../utils/webview";
import {getSubscriptionDefaultSkuData} from "../utils/inapp";
import clientStorage from "../utils/client-storage";
import processingManager from "../photolab/ProcessingManager";
import ImageAnimation from "../components/ImageAnimation/ImageAnimation";

const shownEventIsLogged = {};

export default class SubscribeBannerV2View extends React.Component {
  componentDidMount() {
    this.handleEvent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.handleEvent();
  }

  handleEvent = () => {
    if (!this.props.isOnScreen || shownEventIsLogged[processingManager.processing.id]) {
      return;
    }

    shownEventIsLogged[processingManager.processing.id] = true;

    webviewAnalyticsEvent("banner_shown", [
      clientStorage.getSelectedPhotosAmount(),
      this.props.sku,
      "hotbody_sub_v1",
      "result",
    ], {
      wt_barbify2: {
        pos: this.props.position + 1,
      }
    });
  };

  handleThirdTextClick = (e) => {
    if (e.target.nodeName.toLowerCase() === "span") {
      webviewOpenBrowser("https://pho.to/terms");
    }
  }

  render() {
    let sku = this.context.skus[this.props.sku];
    if (!sku) {
      sku = getSubscriptionDefaultSkuData(
        this.props.sku,
        window.clientConfig.canSubscribeWithTrial ? "P3D" : undefined
      );
    }

    const price = sku ? sku.price : "$9.99";
    const text = window.clientConfig.canSubscribeWithTrial
      ? i18n.t("subscribe_banner__text_2_wt", {price})
      : i18n.t("subscribe_banner__text_2_nt", {price})
      ;

    return <div className="subscribe-banner v2 v3">
      <h2 className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("subscribe_banner_v2__title")}} />
      <ImageAnimation />
      <p className="text-white" dangerouslySetInnerHTML={{__html: i18n.t("subscribe_banner_v2__text_1")}} />
      <p className="text-yellow" dangerouslySetInnerHTML={{__html: text}} />
      <p className="text-terms" dangerouslySetInnerHTML={{__html: i18n.t("subscribe_banner__text_3")}} onClick={this.handleThirdTextClick} />
    </div>;
  }
}

SubscribeBannerV2View.contextType = AppContext;
