import PhotolabTaskBuilder from "../PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../PhotolabTaskImageUrl";
import Creative from "../Creative";
import {
  creativeTimeoutPromise,
  defaultHandlerCatch,
  defaultHandlerResolver,
  promisifyImage
} from "./helpers";
import {photolabTask} from "../api";

const tasksCache = {};

function getResultUrlFromTask(task) {
  if (task.requestId) {
    return task.resultUrl
  } else {
    return task.result.url;
  }
}

// @<name> - processing input files
// #<n> - combo step result files
// &<name> - creative file
// +<name> - task result file
function getFileBySignature(processing, creative, stepIndex, source) {
  const sourceType = source[0];
  const sourceName = source.length > 1 ? source.substring(1) : null;
  let file = {
    url: source,
  };

  if (sourceType === "@") {
    const fileName = sourceName || "0";
    file = processing.getFile(fileName);
  } else if (sourceType === "#") {
    let targetStep = sourceName ? parseInt(sourceName) : stepIndex - 1;
    if (targetStep < 0) {
      file = getFileBySignature(processing, creative, stepIndex, "@");
    } else {
      file.url = getResultUrlFromTask(creative.getTask("s" + targetStep));
    }
  } else if (sourceType === "&") {
    file.url = creative.getFile(sourceName);
  } else if (sourceType === "+") {
    file.url = creative.getTask(sourceName).resultUrl;
  } else {
    file.url = source;
  }

  return file;
}

function templateStep(processing, creative, stepIndex, stepConfig) {
  if (!stepConfig.id) {
    return Promise.reject("No template ID parameter.");
  }

  const templateParams = stepConfig.templateParams || {};

  if (templateParams.gender && templateParams.gender[0] === "#") {
    const genderTask = creative.getTask("s" + templateParams.gender.substring(1));

    if (genderTask) {
      templateParams.gender = genderTask.gender.value === "male" ? "man" : "woman";
    } else {
      delete templateParams.gender;
    }
  }

  const taskConfigBuilder = new PhotolabTaskBuilder()
    .setLanguage(processing.language)
    .addMethod(new PhotolabTaskCollageMethod(stepConfig.id, templateParams));

  (stepConfig.images || [{src: "#"}]).forEach((image) => {
    const file = getFileBySignature(processing, creative, stepIndex, image.src || "#");
    const maskFile = getFileBySignature(processing, creative, stepIndex, image.maskSrc || "@mask_0");
    const altBody = (image.useAltBody && maskFile) ? maskFile.url : "";
    const fileName = altBody.split("/").pop();
    const hash = fileName.substring(0, fileName.lastIndexOf("."));

    taskConfigBuilder.addImage(new PhotolabTaskImageUrl(
      file.url + (hash.length ? "?" + hash : ""),
      file.rect || "",
      file.rotation || 0,
      file.flip || 0,
      altBody
    ));
  });

  const taskConfig = taskConfigBuilder.buildToJs();
  const taskCacheKey = JSON.stringify(taskConfig);

  if (!tasksCache[taskCacheKey]) {
    tasksCache[taskCacheKey] = photolabTask(taskConfig, {
      timeout: stepConfig.getResultTimeout || 1000,
      interval: stepConfig.getResultInterval || 1000,
    });
  }

  if (window.clientConfig.isDebug) {
    creative.setTaskConfig("s" + stepIndex, taskConfig);
  }

  return tasksCache[taskCacheKey].then((taskResult) => {
    creative.setTask("s" + stepIndex, taskResult);

    if (typeof stepConfig.setAsFile === "string" && stepConfig.setAsFile.length > 0) {
      creative.setFile(stepConfig.setAsFile, taskResult.resultUrl);
    }

    return taskResult;
  }).catch((err) => {
    if (err.name === "PhotolabResponseError" && err.code === -1028) {
      if (stepConfig.skipOnMultifaceError) {
        const imageUrl = stepIndex === 0
          ? processing.getFile(0).url
          : getResultUrlFromTask(creative.getTask("s" + (stepIndex-1)));

        const result = {
          skipped: "skipOnMultifaceError",
          result: {url: imageUrl}
        };

        creative.setTask("s" + stepIndex, result);
        return result;
      }

      if (stepConfig.fallbackId) {
        const newStepConfig = JSON.parse(JSON.stringify(stepConfig));
        newStepConfig.id = newStepConfig.fallbackId;
        delete newStepConfig.fallbackId;
        return templateStep(processing, creative, stepIndex, newStepConfig);
      }
    }

    delete tasksCache[taskCacheKey];
    throw err;
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export default (processing, creative) => {
  const steps = creative.getExtra(Creative.EXTRA_COMBO_STEPS);

  function waitChain(index) {
    return new Promise((resolve, reject) => {
      const step = steps[index];
      let stepHandler = null;

      switch (step.type || "template") {
        case "template": {
          stepHandler = templateStep(processing, creative, index, step);
          break;
        }
        default: {
          throw new Error(`Unrecognized combo step: '${step.type}'.`);
        }
      }

      return stepHandler
        .then((res) => steps[index + 1] ? waitChain(index + 1) : res)
        .then(resolve)
        .catch(reject);
    });
  }

  const timeoutMs = creative.getExtra(
    Creative.EXTRA_PROCESSING_TIMEOUT,
    window.appConfig.processings.creativeTimeout
  );

  return new Promise((resolve, reject) => {
    // todo остановить waitChain
    Promise.race([
      creativeTimeoutPromise(timeoutMs),
      waitChain(0),
    ])
    .then(() => {
      const file = creative.getFile("raw_watermark") || creative.getFile("raw");

      return promisifyImage(file)
        .then(() => {
          if (window.clientConfig.isPro) {
            return Promise.resolve();
          } else {
            return new Promise((resolve) => {
              setTimeout(resolve, window.clientConfig.features.freeProcessingsDelay);
            });
          }
        })
        .then(() => creative.markAsProcessed(file));
    })
    .then(defaultHandlerResolver(creative, resolve))
    .catch(defaultHandlerCatch(creative, reject));
  });
}
