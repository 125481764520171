const VERSION = 1;

const KEY_PREFS = "ml:prefs";
const KEY_PREFS_VERSION = "ml:prefs:version";

const KEY_CLIENT_TOKEN = "client_token";
const KEY_SPLIT_GROUP_ID = "split_group_id";
const KEY_WEBVIEW_REQUEST_PARAMS = "webview_request_params";
const KEY_FIRST_VISIT_AT = "first_visit_at";
const KEY_FIRST_PROCESSING_PROCESSED_AT = "first_processing_processed_at";
const KEY_PROCESSED_PHOTOS_AMOUNT = "processed_photos_amount";
const KEY_SELECTED_PHOTOS_AMOUNT = "selected_photos_amount";
const KEY_SELECTED_LANG = "selected_lang";
const KEY_LATEST_SELECTED_IMAGES = "latest_selected_images";
const KEY_AWAITING_BILLED_SKU = "awaiting_billed_sku";
const KEY_PRE_RESULT_BANNER_SESSION_IDX = "pre_result_banner_session_idx";
const KEY_LATEST_PROCESSING_BANNER_ID = "latest_processing_banner_id";
const KEY_PROCESSINGS_COUNT = "processings_count";

class ClientStorage {

  constructor() {
    const prefs = window.localStorage.getItem(KEY_PREFS);
    if (prefs) {
      this.prefs = JSON.parse(prefs);
    } else {
      this.prefs = {};
    }

    const currentVersion = parseInt(window.localStorage.getItem(KEY_PREFS_VERSION) || 1);
    if (currentVersion !== VERSION) {
      this.migrate(currentVersion, VERSION);
      window.localStorage.setItem(KEY_PREFS_VERSION, "" + VERSION);
    }
  }

  migrate(fromVersion, toVersion) {
    // ...
  }

  commit() {
    window.localStorage.setItem(KEY_PREFS, JSON.stringify(this.prefs));
  }

  setBoolean(key, value) {
    this.prefs[key] = !!value;
    this.commit();
  }

  getBoolean(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return !!this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  setInteger(key, value) {
    this.prefs[key] = parseInt(value);
    this.commit();
  }

  getInteger(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return parseInt(this.prefs[key]);
    } else {
      return defaultValue;
    }
  }

  setString(key, value) {
    this.prefs[key] = "" + value;
    this.commit();
  }

  getString(key, defaultValue) {
    if (this.prefs[key] !== undefined) {
      return "" + this.prefs[key];
    } else {
      return defaultValue;
    }
  }

  // ---

  getClientToken() {
    return this.getString(KEY_CLIENT_TOKEN);
  }

  setClientToken(value) {
    this.setString(KEY_CLIENT_TOKEN, value);
  }

  getFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT);
  }

  setFirstVisitAt(value) {
    this.setInteger(KEY_FIRST_VISIT_AT, value);
  }

  hasFirstVisitAt() {
    return this.getInteger(KEY_FIRST_VISIT_AT, Number.MIN_VALUE) !== Number.MIN_VALUE;
  }

  getFirstProcessingProcessedAt() {
    return this.getInteger(KEY_FIRST_PROCESSING_PROCESSED_AT);
  }

  setFirstProcessingProcessedAt(value) {
    this.setInteger(KEY_FIRST_PROCESSING_PROCESSED_AT, value);
  }

  hasFirstProcessingProcessedAt() {
    return this.getInteger(KEY_FIRST_PROCESSING_PROCESSED_AT, Number.MIN_VALUE) !== Number.MIN_VALUE;
  }

  setWebviewRequestParams(value) {
    if (typeof value !== 'object' || value === null) {
      value = {};
    }

    this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] = value;
    this.commit();
  }

  getWebviewRequestParams() {
    if (this.prefs[KEY_WEBVIEW_REQUEST_PARAMS] === undefined) {
      return {};
    }

    return this.prefs[KEY_WEBVIEW_REQUEST_PARAMS];
  }

  getSplitGroupId() {
    return this.getInteger(KEY_SPLIT_GROUP_ID, undefined);
  }

  setSplitGroupId(value) {
    this.setInteger(KEY_SPLIT_GROUP_ID, value);
  }

  getSelectedPhotosAmount() {
    return this.getInteger(KEY_SELECTED_PHOTOS_AMOUNT, 0);
  }

  incrementSelectedPhotosAmount() {
    this.setInteger(KEY_SELECTED_PHOTOS_AMOUNT, this.getSelectedPhotosAmount() + 1);
  }

  getProcessedPhotosAmount() {
    return this.getInteger(KEY_PROCESSED_PHOTOS_AMOUNT, 0);
  }

  incrementProcessedPhotosAmount() {
    this.setInteger(KEY_PROCESSED_PHOTOS_AMOUNT, this.getProcessedPhotosAmount() + 1);
  }

  getSelectedLang() {
    return this.getString(KEY_SELECTED_LANG, null);
  }

  setSelectedLang(value) {
    this.setString(KEY_SELECTED_LANG, value);
  }

  getShouldRedirectToResult() {
    return this.getBoolean("should_redirect_to_result", false);
  }

  setShouldRedirectToResult(value) {
    this.setBoolean("should_redirect_to_result", value);
  }

  getLatestSelectedImages() {
    const value = this.prefs[KEY_LATEST_SELECTED_IMAGES];

    return Array.isArray(value)
      ? value
      : [];
  }

  setLatestSelectedImages(value) {
    this.prefs[KEY_LATEST_SELECTED_IMAGES] = value;
    this.commit();
  }

  hasLatestSelectedImages() {
    return this.getLatestSelectedImages().length > 0;
  }

  clearAwaitingBilledSku() {
    this.prefs[KEY_AWAITING_BILLED_SKU] = undefined;
    this.commit();
  }

  setAwaitingBilledSku(sku) {
    this.setString(KEY_AWAITING_BILLED_SKU, sku);
  }

  getAwaitingBilledSku() {
    return this.getString(KEY_AWAITING_BILLED_SKU);
  }

  getPreResultBannerSessionIdx() {
    return this.getInteger(KEY_PRE_RESULT_BANNER_SESSION_IDX, 0);
  }

  setPreResultBannerSessionIdx(id) {
    this.setInteger(KEY_PRE_RESULT_BANNER_SESSION_IDX, id);
  }

  setLatestProcessingBannerId(id) {
    this.setString(KEY_LATEST_PROCESSING_BANNER_ID, id);
  }

  getLatestProcessingBannerId() {
    return this.getString(KEY_LATEST_PROCESSING_BANNER_ID);
  }

  setProcessingsCount(value) {
    this.prefs[KEY_PROCESSINGS_COUNT] = value;
    this.commit();
  }

  getProcessingsCount() {
    return this.prefs[KEY_PROCESSINGS_COUNT];
  }
}

export default new ClientStorage();
