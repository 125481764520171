import React, {Component} from 'react';
import {mapSelectedImageItem, webviewCall} from "../utils/webview";
import {hitEvent, hits} from "../utils/log";
import AppContext from "../contexts/AppContext";
import Button from '../components/Button/Button';
import TouchEffect from './TouchEffect/TouchEffect';
import LimitModal from './LimitModal';
import LimitModalV2 from './LimitModalV2';

export default class FileChooseButton extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      image: null,
    };

    this.fileFieldRef = React.createRef();

    this.handleBrowserFilesSelected = this.handleBrowserFilesSelected.bind(this);
    this.handleWebviewFilesSelected = this.handleWebviewFilesSelected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleError = this.handleError.bind(this);
    this.triggerSelectFile = this.triggerSelectFile.bind(this);
    this.logFileSelected = this.logFileSelected.bind(this);
  }

  componentDidMount() {
    window.webviewEventsListeners.photoSelected.setListener(this.handleWebviewFilesSelected);
  }

  componentWillUnmount() {
    window.webviewEventsListeners.photoSelected.removeListener();
  }

  triggerSelectFile() {
    if (window.clientConfig.isWebview) {
      const params = {
        func: "onNativeAppPhotoSelected",
        use_crop: 1,
        asp: 0.75,
        num_photos: this.props.numPhotos || 1,
        show: "gallery",
        tab: "faces",
        neurocamera: 1,
      };

      webviewCall("nativePhotoSelect", params);
    } else {
      if (this.fileFieldRef.current) {
        this.fileFieldRef.current.value = "";
        this.fileFieldRef.current.click();
      }
    }
  }

  handleError(code, message) {
    this.props.onError && this.props.onError(code, message);
  }

  logFileSelected() {
    hitEvent(hits.PHOTO_SELECT);
  }

  handleBrowserFilesSelected(files) {
    this.logFileSelected();
    this.props.onFilesSelected && this.props.onFilesSelected(files);
  }

  handleApproveFileChosen = (e) => {
    if (window.clientConfig.isWebview) {
      this.handleClick(e);
    } else {
      const input = document.createElement("input");
      input.style.display = "none";
      input.type = "file";
      input.click();
      input.addEventListener("change", (e) => {
        this.handleBrowserFilesSelected(e.target.files);
      })
    }
  }

  handleFileChooseButtonClick = (e) => {
    e.preventDefault();

    if (this.props.hideLimitModal) {
      this.handleApproveFileChosen(e);
      return;
    }

    const processingsCount = this.context.countProcessings;

    if (!window.clientConfig.isPro && window.clientConfig.limitProcessings) {
      if (processingsCount >= window.clientConfig.limitProcessings) {
        this.context.pushModal(<LimitModalV2
          key="IndexPage_LimitModalV2"
          background="#ff62a5"
          className="limit-modal"
        />);
      } else if (processingsCount === window.clientConfig.limitProcessings - 1) {
        this.context.pushModal(<LimitModal
          key="IndexPage_LimitModal"
          background="#ff62a5"
          className="limit-modal"
          onClick={() => {
            this.handleApproveFileChosen(e);
          }}
        />);
      } else {
        this.handleApproveFileChosen(e);
      }
    } else {
      this.handleApproveFileChosen(e);
    }
  };

  handleWebviewFilesSelected(data) {
    if (!data || !data.photos || data.photos.length === 0) {
      return;
    }

    this.logFileSelected();

    const files = data.photos.map((item) => mapSelectedImageItem(item));

    this.props.onFilesSelected && this.props.onFilesSelected(files);
  }

  handleClick(e) {
    this.props.onClick && this.props.onClick(e);
    this.triggerSelectFile();
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return <React.Fragment>
      <Button
        disabled={this.props.disabled}
        className={this.props.className}
        onClick={this.handleFileChooseButtonClick}
        primary={this.props.primary}
      >
        <TouchEffect />
        {this.props.children}
      </Button>

      <input
        className="file-field-hidden"
        type="file"
        accept="image/*"
        multiple={this.props.numPhotos > 1}
        ref={this.fileFieldRef}
        onClick={this.handleFileChooseButtonClick}
      />
    </React.Fragment>;
  }
}

FileChooseButton.contextType = AppContext;
