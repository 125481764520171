import Creative from "../Creative";
import {handlersNames} from "../handlers";
import {extraKeys, genderKeys, hairStylizationKeys, stylizationKeys} from "../etc";

const commonParams = {
  head: {
    canvas_template_name: 8663,
    mask_template_name: 8664,
  },
  face: {
    canvas_template_name: 8659,
    mask_template_name: 8810,
    mask_blur: 0,
  },
};

const builderNames = {
  barbify2: "barbify2",
  barbifyAdm12: "barbify_adm12",
  hotify: "hotify",
};

const stylizationsSteps = {
  [stylizationKeys.doll]: [
    {id: 6472},
    {id: 8666}
  ],
  [stylizationKeys.glamour]: [
    {id: 7088}
  ],
};

const strengths = {
  low: 0.45,
  medium: 0.6,
  super: 0.75,
};

export function createCreativeByPromptConfig(
  processing,
  itemConfig,
  gender,
  stylization,
  hairStylization,
  strength
) {
  const context = {
    gender,
    stylization,
    hairStylization,
    strength
  };

  switch (itemConfig.builder) {
    case builderNames.barbify2: {
      return createBarbifyCreative(processing, itemConfig, context);
    }
    case builderNames.barbifyAdm12: {
      return createBarbifyAdm12Creative(processing, itemConfig, context);
    }
    case builderNames.hotify: {
      return createHotifyCreative(processing, itemConfig, context);
    }
    default: {
      throw new Error("Unrecognized config builder: " + itemConfig.builder);
    }
  }
}

function createBarbifyCreative(processing, itemConfig, context) {
  const templateStep = {
    id: itemConfig.isCtrlNet ? 8681 : 8658,
    templateParams: {
      "@transformationId": "barbify2",
      "@configId": itemConfig.configId || itemConfig.id,
      param_s: strengths[context.strength],
      ...itemConfig.params,
      ...commonParams[context.hairStylization === hairStylizationKeys.yes ? "face" : "head"],
      gender: context.gender
        .replace("female", "woman")
        .replace("male", "man"),
    },
    setAsFile: "raw",
  };

  // у мужиков меняем barbie на ken в промпте
  if (context.gender === genderKeys.male) {
    templateStep.templateParams["@replacements"] = [{
      parameters: ["prompt"],
      search: "barbie",
      mode: "ig",
      replacement: "Ken",
    }];
  }

  if (itemConfig.isCtrlNet) {
    switch (context.strength) {
      case "low": {
        templateStep.templateParams.param_ipfl = 1;
        templateStep.templateParams.param_gm = "TRUE";
        templateStep.templateParams.param_s = 0.4;
        break;
      }
      case "medium": {
        templateStep.templateParams.param_ipfl = 3;
        templateStep.templateParams.param_gm = "FALSE";
        templateStep.templateParams.param_s = 0.7;
        break;
      }
      case "super": {
        templateStep.templateParams.param_ipfl = 3;
        templateStep.templateParams.param_gm = "TRUE";
        templateStep.templateParams.param_s = 0.8;
        break;
      }
      default:
    }
  }

  const steps = [
    ...stylizationsSteps[context.stylization],
    templateStep,
  ];

  steps.first().images = [{src: "@", useAltBody: true}];

  const creative = new Creative();
  creative.setGroup("general");
  creative.setTemplateId(itemConfig.id);
  creative.setHandler(handlersNames.COMBO);
  creative.setExtra(Creative.EXTRA_COMBO_STEPS, steps);
  creative.setExtra(extraKeys.gender, context.gender);
  creative.setExtra(extraKeys.stylization, context.stylization);
  creative.setExtra(extraKeys.hairStylization, context.hairStylization);
  creative.setExtra(extraKeys.strength, context.strength);

  return creative;
}

function createBarbifyAdm12Creative(processing, itemConfig, context) {
  const templateStep = {
    id: 8815,
    templateParams: {
      "@transformationId": "barbify2",
      "@configId": itemConfig.configId || itemConfig.id,
      canvas_template_name: 8663,
      mask_template_name: 8664,
      pose_template_name: 8663,
      param_s: 0.6,
      ...itemConfig.params,
      ...commonParams[context.hairStylization === hairStylizationKeys.yes ? "face" : "head"],
      gender: context.gender
        .replace("female", "woman")
        .replace("male", "man"),
    },
    setAsFile: "raw",
  };

  if (context.gender === genderKeys.male) {
    templateStep.templateParams["@replacements"] = [{
      parameters: ["prompt"],
      search: "barbie",
      mode: "ig",
      replacement: "Ken",
    }];
  }

  const steps = [
    ...stylizationsSteps[context.stylization],
    templateStep,
  ];

  steps.first().images = [{src: "@", useAltBody: true}];

  const creative = new Creative();
  creative.setGroup("general");
  creative.setTemplateId(itemConfig.id);
  creative.setHandler(handlersNames.COMBO);
  creative.setExtra(Creative.EXTRA_COMBO_STEPS, steps);
  creative.setExtra(extraKeys.gender, context.gender);
  creative.setExtra(extraKeys.stylization, context.stylization);
  creative.setExtra(extraKeys.hairStylization, context.hairStylization);
  creative.setExtra(extraKeys.strength, context.strength);

  return creative;
}

function createHotifyCreative(processing, itemConfig, context) {
  const steps = [
    ...stylizationsSteps[context.stylization],
    {
      id: 8778,
      templateParams: {
        "@transformationId": "hotify",
        "@configId": itemConfig.configId || itemConfig.id,
        param_pp: "true, true", // pixel_perfect
        param_ctwg: "0.5, 1", // control_weights
        param_ctmds: "2, 1", // control_modes
        param_ctmdl: "dm_of", // ctrlnet_modules: depth_midas, openpose_full
        param_ns: 30, // num_steps
        param_s: 0.8, // strength
        canvas_template_name: 8663,
        mask_template_name: 8664,
        pose_template_name: 8663,
        gender: context.gender
          .replace("female", "woman")
          .replace("male", "man"),
      },
      setAsFile: "raw",
      ignorePhotolabError: {code: 1},
      timeout: 150_000,
    },
  ];

  steps.first().images = [{src: "@", useAltBody: true}];

  const creative = new Creative();
  creative.setGroup("general");
  creative.setTemplateId(itemConfig.id);
  creative.setHandler(handlersNames.COMBO);
  creative.setExtra(Creative.EXTRA_COMBO_STEPS, steps);
  creative.setExtra(extraKeys.gender, context.gender);
  creative.setExtra(extraKeys.stylization, context.stylization);
  creative.setExtra(extraKeys.hairStylization, context.hairStylization);
  creative.setExtra(extraKeys.strength, context.strength);

  return creative;
}

function getPrompts() {
  return [
    {id: "barbify_ai_1", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_2", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_5", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_550", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_552", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_553", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_554", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_546", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_547", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_548", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_549", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_551", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_696", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_700", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_703", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_705", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_709", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_713", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_716", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_719", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_728", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_747", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_ai_dragon", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "barbify_adm12", builder: builderNames.barbifyAdm12},
    {id: "barbify_adm12_1", builder: builderNames.barbifyAdm12},
    {id: "barbify_adm12_2", builder: builderNames.barbifyAdm12},
    {id: "barbify_adm12_3", builder: builderNames.barbifyAdm12},
    {id: "barbify_adm12_4", builder: builderNames.barbifyAdm12},
    {id: "barbify_htfy_rain", builder: builderNames.hotify},
  ];
}

export function getPromptsForSingle() {
  const prompts = getPrompts();
  const males = [
    "barbify_ai_705",
    ...[
      "barbify_ai_713",
      "barbify_ai_709",
      "barbify_ai_728",
    ].shuffle(),
    "barbify_ai_696",
    "barbify_ai_1",
    "barbify_ai_5",
    "barbify_ai_554",
    "barbify_ai_2",
    "barbify_ai_550",
  ];

  const females = [
    ...[
      "barbify_adm12",
      "barbify_adm12_1",
      "barbify_adm12_2",
      "barbify_adm12_3",
      "barbify_adm12_4",
    ].shuffle(),
    ...[
      "barbify_ai_547",
      "barbify_htfy_rain",
    ].shuffle(),
    ...[
      "barbify_ai_553",
      "barbify_ai_716",
      "barbify_ai_552",
      "barbify_ai_546",
      "barbify_ai_551",
      "barbify_ai_719",
      "barbify_ai_705",
      "barbify_ai_548",
    ].shuffle(),
    ...[
      "barbify_ai_549",
      "barbify_ai_550",
      "barbify_ai_700",
      "barbify_ai_747",
    ].shuffle()
  ];

  return [
    ...males.map((itemId) => {
      let item = prompts.find((p) => p.id === itemId);
      item = JSON.parse(JSON.stringify(item));
      item.genders = [genderKeys.male];
      return item;
    }),
    ...females.map((itemId) => {
      let item = prompts.find((p) => p.id === itemId);
      item = JSON.parse(JSON.stringify(item));
      item.genders = [genderKeys.female];
      return item;
    }),
  ];
}

export function getPromptsForCouples() {
  return [
    {id: "couple_237", configId: "barbify_couple_237", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "couple_238", configId: "barbify_couple_238", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "couple_239", configId: "barbify_couple_239", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "couple_240", configId: "barbify_couple_240", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "couple_241", configId: "barbify_couple_241", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "couple_242", configId: "barbify_couple_242", builder: builderNames.barbify2, isCtrlNet: true},
    {id: "couple_243", configId: "barbify_couple_243", builder: builderNames.barbify2, isCtrlNet: true},
  ];
}
