import clientStorage from "./client-storage";

export const processingBanner = {
  v1: "v1",
  v2: "v2",
}

const processingBannerList = [
  processingBanner.v1,
  processingBanner.v2
];

export function getNextProcessingBannerId() {
  const latestBannerId = clientStorage.getLatestProcessingBannerId();
  const latestIndex = processingBannerList.findIndex((v) => v === latestBannerId);
  const nextIndex = processingBannerList[(latestIndex + 1) % processingBannerList.length];

  clientStorage.setLatestProcessingBannerId(nextIndex);

  return nextIndex;
}
