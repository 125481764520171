import styled from "styled-components";

export const ImageAnimationStyled = styled.div`
    @keyframes toggleImage {
        0% { opacity: 0; }
        11.11% { opacity: 1; }
        33.33% { opacity: 1; }
        44.44% { opacity: 0; }
        100% { opacity: 0; }
    }

    width: 100%;
    position: relative;
    flex: 1;
    min-height: 80px;

    img {
        padding: 0 24px;
        height: 100%;
        width: 100%;
        position: absolute;
        object-fit: contain;
        top: 0;
        left: 0;
        opacity:0;
        object-fit: contain;
        animation: toggleImage 15s infinite; 

        &:nth-child(1) { 
            animation-delay: 0s; 
        }
        &:nth-child(2) { 
            animation-delay: 3s; 
        }
        &:nth-child(3) { 
            animation-delay: 6s; 
        }
        &:nth-child(4) { 
            animation-delay: 9s; 
        }
        &:nth-child(5) { 
            animation-delay: 12s; 
        }
    }
`;