import styled from "styled-components";

export const ButtonStyled = styled.button`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  color: ${props => props.primary ? '#fff' : '#FF62A5'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: ${props => props.primary ? "#FF62A5" : "transparent"};
  padding: 0 12px;
  border-radius: 32px;
  border: 1.5px solid #FF62A5;
  height: 48px;
  width: 100%;
  max-width: 180px;
  margin: 0 auto;
  margin-bottom: ${props => props.marginBottom}px;
  overflow: hidden; 

  // &:hover {
  //     background-color: ${props => props.primary ? "#d5c6f8" : "#ffcb20"};
  // }

  &.underline {
    font-family: Helvetica, sans-serif;
    padding: 0;
    background: none;
    text-decoration: underline;
    font-weight: 400;
    color: #999;
    border: none;
    box-shadow: none;
    text-transform: unset;
  }

  &.watermark {
    width: ${props => props.watermark && "50px"};
    svg {
      display: ${props => props.watermark ? "block" : "none"};
      width: 18px;
      fill: #000;
    }

    span {
      display: ${props => props.watermark && "none"};
    }
  }
`;
