import React from "react";
import {DropdownSelectorButtonStyled, DropdownSelectorContainerStyled, DropdownSelectorListStyled, DropdownSelectorListButtonStyled, DropdownSelectorListItemStyled, DropdownSelectorListIconContainerStyled} from "./DropdownSelector.style";
import TouchEffect from "../TouchEffect/TouchEffect";
import { assetUrl } from "../../utils/etc";

export default class DropdownSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDropdownActive: false
    }

    this.dropdownButtonRef = React.createRef();
  }

  componentDidMount() {
    document.body.addEventListener("click", this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    if (this.dropdownButtonRef.current && !this.dropdownButtonRef.current.contains(e.target)) {
      this.setState({isDropdownActive: false});
    }
  }

  toggleDropdown = () => {
    this.setState({
      isDropdownActive: !this.state.isDropdownActive,
    });
  }

  handleChangeDropdown = (gender) => {
    this.setState({
      isDropdownActive: false,
    });

    this.props.onChange && this.props.onChange(gender);
  }

  render() {
    const {isDropdownActive} = this.state;
    const {items, value, hidden} = this.props;
    const selectedItem = items.find((item) => item.key === value);

    if (hidden) {
      return <React.Fragment />;
    }

    return (
      <DropdownSelectorContainerStyled ref={this.dropdownButtonRef}>
        <DropdownSelectorButtonStyled
          active={!isDropdownActive}
          onClick={this.toggleDropdown}>
          {selectedItem && <img src={assetUrl(`/assets/images/${selectedItem.icon}_icon.svg`)} alt="" />}
          <TouchEffect />
        </DropdownSelectorButtonStyled>
        <DropdownSelectorListStyled hidden={!isDropdownActive}>
          {items.map((item, i) => <DropdownSelectorListItemStyled key={i}>
            <DropdownSelectorListButtonStyled activeListItem={item.key === value} onClick={() => this.handleChangeDropdown(item.key)}>
              <DropdownSelectorListIconContainerStyled>
                <img src={assetUrl(`/assets/images/${item.icon}_icon.svg`)} alt="" />
              </DropdownSelectorListIconContainerStyled>
              <p>{item.label}</p>
              {item.isPro && <p className="label">pro</p>}
              <TouchEffect />
            </DropdownSelectorListButtonStyled>
          </DropdownSelectorListItemStyled>
          )}
        </DropdownSelectorListStyled>
      </DropdownSelectorContainerStyled>
    )
  }
}
