import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";

export default class RewardedAdBannerView extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isOnScreen) {
      if (!this.shownEventIsLogged) {
        this.shownEventIsLogged = true;
      }
    } else {
      this.shownEventIsLogged = false;
    }
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    return <div className="subscribe-banner">
      <h2 dangerouslySetInnerHTML={{__html: i18n.t("rewarded_ad_banner__text")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("rewarded_ad_banner__subtext")}} />
    </div>;
  }
}

RewardedAdBannerView.contextType = AppContext;
