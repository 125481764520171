import React from "react";
import { Container, Wrapper, RollerContainer, RollerContainer2, RollerContainer3, Roller1, Roller2, Roller21, Roller22, Roller31, Roller32 } from "./Loader.style";

export default function Loader(props) {
  if (props.hidden) {
    return <React.Fragment />;
  }

  return <Container>
    <Wrapper>
      <RollerContainer>
        <Roller1 />
        <Roller2 />
      </RollerContainer>
      <RollerContainer2>
        <Roller21 />
        <Roller22 />
      </RollerContainer2>
      <RollerContainer3>
        <Roller31 />
        <Roller32 />
      </RollerContainer3>
    </Wrapper>
  </Container>;
}
