import comboHandler from "./combo.handler";

export const handlersNames = {
  COMBO: "combo",
};

const handlersMap = {
  [handlersNames.COMBO]: comboHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}
