import React from "react";
import Modal from "../components/Modal";
import i18n from "../i18n";
import TouchEffect from "./TouchEffect/TouchEffect";
import {webviewShowBanner} from "../utils/webview";

const limitList = [
    i18n.t("limit_modal__list_item_1"),
    i18n.t("limit_modal__list_item_2"),
    i18n.t("limit_modal__list_item_3"),
    i18n.t("limit_modal__list_item_4"),
];

export default class LimitModalV2 extends Modal {

    state = {
        copied: false,
    }

    componentDidMount() {
        window.webviewEventsListeners.backPress.push(() => {
            this.dismiss();
            return true;
        });
    }

    dismiss(trigger) {
        window.webviewEventsListeners.backPress.pop();
        super.dismiss(trigger);
    }

    handleCloseButtonClick = (e) => {
        this.dismiss();
    }

    handleUpgradeClick = () => {
        webviewShowBanner("navbarbut", () => {
            //
        });
        this.dismiss();
    }

    renderModal() {
        return <React.Fragment>
            <h2 className="limit-modal__text title" dangerouslySetInnerHTML={{__html: i18n.t("limit_modal_v2__title")}} />
            <h1 className="limit-modal__list-title text-yellow" dangerouslySetInnerHTML={{__html: i18n.t("limit_modal__list_title")}} />
            <ul className="limit-modal__list">
                {limitList.map((item, i) => (
                    <li className="text-yellow" key={i}>{item}</li>
                ))}
            </ul>
            <p className="limit-modal__text v2" dangerouslySetInnerHTML={{__html: i18n.t("limit_modal_v2__text")}} />
            <div className="limit-modal__btns">
                <button
                    onClick={this.handleUpgradeClick}
                >
                    {i18n.t("limit_modal__upgrade_btn")}
                    <SvgArrowIcon />
                    <TouchEffect />
                </button>
                <button
                    className="without-bg"
                    onClick={this.handleCloseButtonClick}
                >
                    {i18n.t("limit_modal__continue_btn")}
                    <TouchEffect />
                </button>
            </div>
        </React.Fragment>
    }
}

export function SvgArrowIcon() {
    return <svg version="1.1" width="35px" height="7px" viewBox="0 0 83.0 19.0" xmlns="http://www.w3.org/2000/svg"><defs><clipPath id="i0"><path d="M1242,0 L1242,2208 L0,2208 L0,0 L1242,0 Z"></path></clipPath></defs><g transform="translate(-929.0 -1842.0)"><g clipPath="url(#i0)"><g transform="translate(81.0 252.0)"><g transform="translate(72.0 163.0)"><g transform="translate(0.0 206.0)"><g transform="translate(0.0 336.0)"><g transform="translate(0.0 818.0)"><g transform="translate(480.0 0.0)"><g transform="translate(79.0 43.0)"><g transform="translate(219.0 33.0)"><polygon points="61.5,-9 80.5,0.5 61.5,10 61.5,2 -1.5,2 -1.5,-1 61.5,-1 61.5,-9 61.5,-9" stroke="none" fill="#000000"></polygon></g></g></g></g></g></g></g></g></g></g></svg>
}