import Creative from "./Creative";
import {extraKeys} from "./etc";

export default class Processing {

  static SELECTED_GROUP = "selected_group";
  static EXTRA_CREATED_AT = "created_at";
  static EXTRA_STARTED_AT = "started_at";
  static EXTRA_IS_FORKED = "is_forked";
  static EXTRA_PREVIEWS = "previews";
  static EXTRA_SELECTED_GENDER = "selected_gender";
  static EXTRA_DETECTED_GENDER = "detected_gender";
  static EXTRA_RANDOM_GENDER = "random_gender";
  static EXTRA_PRO_WATERMARK_SHOULD_BE_REMOVED = "pro_watermark_should_be_removed";
  static EXTRA_FIRST_DOWNLOAD_AT = "first_download_at";
  static EXTRA_SHOW_ORIGINAL_STICKER = "show_original_sticker";
  static EXTRA_ORIGINAL_STICKER_URL = "original_sticker_url";
  static EXTRA_CROPPED_IMAGE_URL = "cropped_image_url";
  static EXTRA_CROPPED_IMAGES_URLS = "cropped_images_urls";
  static EXTRA_ITEMS = "items";
  static EXTRA_ITEMS_ORDER = "items_order";
  static EXTRA_ITEMS_ORDER_MALE = "items_order_male";
  static EXTRA_ITEMS_ORDER_FEMALE = "items_order_female";
  static EXTRA_IS_MULTIFACE = "is_multiface";

  _id = 0;
  _files = [];
  _groups = [];
  _creatives = [];
  _language = "en";
  _extra = {};
  _tasks = {};

  get id() {
    return this._id;
  }

  get files() {
    return this._files.map((item) => item.file);
  }

  get language() {
    return this._language;
  }

  /** @returns {Creative[]} */
  get creatives() {
    return this._creatives;
  }

  get groups() {
    return this._groups;
  }

  get extra() {
    return this._extra;
  }

  setId(id) {
    this._id = id;
  }

  setFile(file, tags) {
    this._files.push({
      file,
      tags: tags.map((tag) => String(tag)),
    });
  }

  getFile(tag) {
    const file = this._files.find((item) => item.tags.includes(String(tag)));
    return file ? file.file : null;
  }

  setGroups(groups) {
    this._groups = groups;
  }

  setLanguage(language) {
    this._language = language || "en";
  }

  setTask(key, task) {
    this._tasks[key] = task;
  }

  getTask(key) {
    return this._tasks[key] || null;
  }

  shuffleCreatives() {
    this._creatives = this._creatives.shuffle();
  }

  addCreative(creative) {
    this.creatives.push(creative);
  }

  replaceCreative(oldCreative, newCreative) {
    const index = this.creatives.findIndex(c => c.id === oldCreative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1, newCreative);
    }
  }

  removeCreative(creative) {
    const index = this.creatives.findIndex(c => c.id === creative.id);

    if (index !== -1) {
      this.creatives.splice(index, 1);
    }
  }

  getCreativesInGroup(group) {
    let creatives = this.creatives.filter((c) => c.inGroup(group));

    return creatives;
  }

  getSelectedCreativeInGroup(group, gender) {
    return this.creatives.find((c) => {
      return c.inGroup(group)
        && c.isSelected
        && c.getExtra(Creative.EXTRA_REF, []).includes(gender)
    });
  }

  setExtra(key, value) {
    this._extra[key] = value;
  }

  hasExtra(key) {
    return this._extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this.hasExtra(key)
      ? this._extra[key]
      : defaultValue;
  }

  removeExtra(key) {
    delete this._extra[key];
  }

  getStartedCreatives() {
    return this.creatives.filter((creative) => creative.hasExtra(Creative.EXTRA_STARTED_AT));
  }

  getCreativesByPosition(position) {
    return this.creatives.filter((c) => c.getExtra(Creative.EXTRA_POSITION) === position);
  }

  getSelectedCreativeByPosition(position) {
    return this.getCreativesByPosition(position).find((c) => c.isSelected);
  }

  getCreativeByParams(position, gender, stylization) {
    return this.getCreativesByPosition(position).find((c) => {
      return c.getExtra(extraKeys.gender) === gender && c.getExtra(extraKeys.stylization) === stylization;
    });
  }

  resolveGender(gender) {
    return gender === "human" ? this.getExtra(Processing.EXTRA_RANDOM_GENDER) : gender;
  }

  getItems() {
    return this.getExtra(Processing.EXTRA_ITEMS);
  }

  getItemsOrder(gender) {
    if (this.getExtra(Processing.EXTRA_IS_MULTIFACE)) {
      return this.getExtra(Processing.EXTRA_ITEMS_ORDER);
    }

    gender = this.resolveGender(gender || this.getExtra("gender"));

    return gender === "male"
      ? this.getExtra(Processing.EXTRA_ITEMS_ORDER_MALE)
      : this.getExtra(Processing.EXTRA_ITEMS_ORDER_FEMALE);
  }

  getItemById(itemId) {
    return this.getItems().find((item) => item.id === itemId);
  }

  getItemByPosition(position, gender) {
    return this.getItemById(this.getItemsOrder(gender)[position]);
  }

  toJSON(pretty = false) {
    const output = {
      id: this._id,
      files: this._files,
      groups: this._groups,
      creatives: this._creatives.map((creative) => creative.data),
      language: this._language,
      extra: this._extra,
      tasks: this._tasks,
    };

    return pretty
      ? JSON.stringify(output, null, 2)
      : JSON.stringify(output);
  }

  fromObject(obj) {
    this._id = obj.id || null;
    this._files = obj.files || null;
    this._groups = obj.groups || [];
    this._language = obj.language || "en";
    this._extra = obj.extra || {};
    this._tasks = obj.tasks || {};
    this._creatives = (obj.creatives || []).map((data) => {
      const creative = new Creative();
      creative.fromObject(data);

      return creative;
    });
  }
}
