import React from "react";
import {TouchEffectContainerStyle, TouchEffectItemStyle} from "./TouchEffect.style";
import PropTypes from "prop-types";

export default class TouchEffect extends React.Component {

  state = {
    coords: [],
  };

  containerRef = null;

  componentDidUpdate(prevProps, prevState, snapshot) {
    // браузер начинает анимацию элемента заново если элемент или его родитель
    // перешли из состояния display: none в видимое
    // для этого проверяем, если компонент выпал из потока то убираем все его прошлые клики
    if (this.containerRef && !this.containerRef.offsetParent) {
      this.state.coords = [];
    }
  }

  handleTouch = (e) => {
    if (this.props.disabled) {
      return;
    }

    const rect = this.containerRef.getBoundingClientRect();
    const touchX = (e.touches && e.changedTouches.length > 0) ? e.changedTouches[0].clientX : e.clientX;
    const touchY = (e.touches && e.changedTouches.length > 0) ? e.changedTouches[0].clientY : e.clientY;

    this.setState({
      coords: [
        ...this.state.coords,
        {
          id: Date.now(),
          x: touchX - rect.left,
          y: touchY - rect.top,
        }
      ].slice(-3),
    });
  };

  handleClick = (e) => {
    this.props.onClick && this.props.onClick(e);
  };

  renderEffect = (item) => {
    return <TouchEffectItemStyle
      key={item.id}
      style={{left: item.x, top: item.y}}
    />;
  };

  render() {
    const listeners = {
      onClick: this.handleClick,
    };

    if (window.clientConfig.isAnyMobile || window.clientConfig.isWebview) {
      listeners.onTouchStart = this.handleTouch;
    } else {
      listeners.onMouseDown = this.handleTouch;
    }

    return <TouchEffectContainerStyle
      {...listeners}
      ref={(ref) => this.containerRef = ref}
      className={this.props.className}
      children={<React.Fragment>
        {this.props.children}
        {this.state.coords.map(this.renderEffect)}
      </React.Fragment>}
    />;
  }
}

TouchEffect.propType = {
  className: PropTypes.string,
};
