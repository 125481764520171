import styled from "styled-components";

export const TouchEffectContainerStyle = styled.div`
  display: inline-block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const TouchEffectItemStyle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #fff;
  opacity: 0.7;
  transform-origin: center;
  transform: translate(-50%, -50%);
  animation: button-android-touch-effect 1s forwards;
  touch-action: none;

  @keyframes button-android-touch-effect {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0.7;
    }
    100% {
      transform: translate(-50%, -50%) scale(20);
      opacity: 0;
    }
  }
`;
