import styled from "styled-components";

export const DropdownSelectorsWrapperStyled = styled.div`
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 15px;
`;

export const DropdownSelectorContainerStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 8px;

  &:first-of-type {
    margin: 0;
  }
`;

export const DropdownSelectorListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  top: 0;
  right: calc(100% + 8px);
`

export const DropdownSelectorListItemStyled = styled.li`
  border-radius: 0;

  &:first-child {
    button {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }

  &:last-child {
    button {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }
`

export const DropdownSelectorActiveButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  background: rgba(17, 17, 17, 0.75);
  backdrop-filter: blur(3px);

  img {
    ${props => props.active ? 'filter: invert(99%) sepia(100%) saturate(2%) hue-rotate(95deg) brightness(106%) contrast(100%);' : 'filter: invert(68%) sepia(10%) saturate(1789%) hue-rotate(274deg) brightness(102%) contrast(96%);'};
    width: 16px;
  }
`;

export const DropdownSelectorButtonStyled = styled(DropdownSelectorActiveButtonStyled)`
  grid-row: 1;
  border-radius: 50%;
  max-width: 32px;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  color: #fff;
  overflow: hidden;
  position: relative;
    
    .touch-effect {
        border-radius: 100%;
    }
`;

export const DropdownSelectorListIconContainerStyled = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DropdownSelectorListButtonStyled = styled(DropdownSelectorActiveButtonStyled)`
  border-radius: 0;
  width: 100%;
  font-size: 12px;
  line-height: 2;
  color: #fff;
  justify-content: flex-start;
  padding-right: 22px;
  
  img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    ${props => props.activeListItem ?' filter: invert(68%) sepia(10%) saturate(1789%) hue-rotate(274deg) brightness(102%) contrast(96%);' : 'filter: invert(100%) sepia(1%) saturate(2%) hue-rotate(234deg) brightness(114%) contrast(100%);'}
  }

  p {
    font-weight: 300;
    color: ${props => props.activeListItem ? '#fa94d6' : '#fff'};
    overflow: hidden;
  }

  .label {
    font-size: 6px;
    line-height: 6px;
    color: #fff;
    text-transform: uppercase;
    background-color: #000;
    width: 24px;
    height: 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    right: 6px;
  }
`;

