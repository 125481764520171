import React from "react";
import {DropdownSelectorsWrapperStyled} from "./DropdownSelector.style";
import DropdownSelector from "./DropdownSelector";

export default class DropdownSelectorsWrapper extends React.Component {
    render() {
        return (
            <DropdownSelectorsWrapperStyled>
                {this.props.selectors.map((selector, i) => (
                    <DropdownSelector
                        {...selector}
                        key={i}
                    />
                ))}
            </DropdownSelectorsWrapperStyled>
        )
    }
}