import {hitEvent, hits} from "./log";
import {webviewPreloadAd, webviewShowAd} from "./webview";

export function canShowNativeAds() {
  return window.appConfig.nativeAds.isEnabled && !window.clientConfig.isPro;
}

export function preloadNativeAds() {
  if (window.clientConfig.isWebview) {
    hitEvent(hits.NATIVE_ADS_PRELOAD);
    webviewPreloadAd(window.clientConfig.features.interstitialAdUnitId);
  } else {
    console.log("NativeAds", "preload");
  }
}

export function showNativeAds() {
  if (window.clientConfig.isWebview) {
    hitEvent(hits.NATIVE_ADS_SHOW_REQUEST);
    webviewShowAd(window.clientConfig.features.interstitialAdUnitId, adShown, adClosed);
  } else {
    console.log("NativeAds", "show");
  }
}

function adShown(params) {
  if (parseInt(params) === 1) {
    hitEvent(hits.NATIVE_ADS_SHOWN);
  }
}

function adClosed() {
  //
}
