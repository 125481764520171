import styled from "styled-components";

export const ProcessingLoaderStyled = styled.div`
    position: relative;
    width: 100%;

    ${props => props.isPro && `
        position: absolute;
        width: calc(100% - 100px);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -120px);
        overflow: hidden;
    `}
`;

export const ProcessingLoaderProgressBarStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 80px;
    margin: 24px auto;

    @media (max-height: 645px) {
        height: 74px;
        width: 74px;
    }

    @media (max-height: 575px) {
        height: 50px;
        width: 50px;
        margin: 12px auto;
    }

    ${props => props.isPro && `
        width: 175px;
        height: 175px;

        @media (max-height: 645px) {
            width: 120px;
            height: 120px;
        }
    `}
`;

export const ProcessingLoaderIndicatorStyled = styled.svg`
    position: absolute;
    height: 100%;
    width: 100%;
    rotate: -90deg;

    circle {
        position: absolute;
        left: 0;
        top: 0;
        cx: 40px;
        cy: 40px;
        r: 39px;
        
        fill: none;
        stroke-width: 1;

        &:last-child {
            stroke-width: 2;
            stroke: #ff62a5;
        }
    }

    @media (max-height: 645px) {
        circle {
            cx: 37px;
            cy: 37px;
            r: 36px;
        }
    }

    @media (max-height: 575px) {
        circle {
            cx: 25px;
            cy: 25px;
            r: 24px;
        }
    }

    ${props => props.isPro && `
        circle {
            cx: 87.5px;
            cy: 87.5px;
            r: 86.5px;
            
            @media (max-height: 645px) {
                cx: 60px;
                cy: 60px;
                r: 59px;
            }
        }
    `}
`;

export const ProcessingLoaderAvatarStyled = styled.img`
    width: 50%;
    height: 50%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    filter: ${props => props.blur ? "blur(2px)" : "none"};
`;

export const ProcessingLoaderTextStyled = styled.p`
    margin-top: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: rgba(34, 34, 34, 0.7);

    @media (max-height: 600px) {
        margin-top: 20px;
    }
`;