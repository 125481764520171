import React from "react";
import i18n from "../../../i18n";
import AppHeader from "../../../components/AppHeader/AppHeader";
import FlexWrap from "../../../components/FlexWrap/FlexWrap";
import {LoadingGenderButtonStyled, LoadingGenderSelectContainer} from "./LoadingView.style";
import TouchEffect from "../../../components/TouchEffect/TouchEffect";
import {getNextProcessingBannerId, processingBanner} from "../../../utils/banners";
import SubscribeProcessingBannerV1View from "../../shared/SubscribeProcessingBannerV1View";
import SubscribeProcessingBannerV2View from "../../shared/SubscribeProcessingBannerV2View";
import ImageAnimation from "../../../components/ImageAnimation/ImageAnimation";
import * as webviewUtils from "../../../utils/webview";

const LOADING_TEXT_INTERVAL = 3000;

const genders = {
  male: "male",
  female: "female",
  human: "human",
};

class LoadingView extends React.Component {

  constructor(props) {
    super(props);

    this.texts = [
      "processing_text_1",
      "processing_text_2",
      "processing_text_3",
      "processing_text_4",
    ];

    this.state = {
      imageIsLoaded: false,
      //textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
      textIndex: 0,
      gender: 'male'
    };

    this.updateTextIndexTimer = null;

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});

    this.processingBannerId = window.clientConfig.features.isSubscribeModel && getNextProcessingBannerId();
  }

  componentDidMount() {
    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        if (this.props.image) {
          this.image.src = this.props.image;
        }
      });
    }

    if (prevProps.isHidden !== this.props.isHidden) {
      if (this.props.isHidden) {
        clearInterval(this.updateTextIndexTimer);
        this.updateTextIndex();
      } else {
        this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
      }
    }
  }

  componentWillUnmount() {
    this.image.onload = () => { };
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  }

  handleCancelButtonClick = () => {
    this.props.onCancel && this.props.onCancel();
  };

  handleBannerButtonClick = () => {
    webviewUtils.webviewShowBanner("postdownload", () => {
      //
    });
  }

  render() {
    let banner = null;
    
    // eslint-disable-next-line default-case
    if (!window.clientConfig.isPro) {
      switch (this.processingBannerId) {
        case processingBanner.v1:
          banner = <SubscribeProcessingBannerV1View>
            <div className="subscribe-banner-view-media-container subscribe-banner-view-media-container_animation">
              <ImageAnimation />
            </div>
            <p dangerouslySetInnerHTML={{__html: i18n.t("subscribe_banner_processing__text")}} />
            <button onClick={this.handleBannerButtonClick}>
              {i18n.t("btn__upgrade")}
              <ArrowIcon />
            </button>
          </SubscribeProcessingBannerV1View>;
          break;
        case processingBanner.v2:
          banner = <SubscribeProcessingBannerV2View>
            <div className="subscribe-banner-view-media-container subscribe-banner-view-media-container_image">
              <ArrowRocket />
            </div>
            <h3 className="text-bold" dangerouslySetInnerHTML={{__html: i18n.t("subscribe_banner_processing__title")}} />
            <button onClick={this.handleBannerButtonClick}>
              {i18n.t("btn__speed_up")}
              <ArrowIcon />
            </button>
          </SubscribeProcessingBannerV2View>;
          break;
      }
    }

    return <main className="loader" hidden={this.props.isHidden}>
      <button
        className="loader-cancel-button"
        onClick={this.handleCancelButtonClick}>
        {i18n.t("cancel")}
      </button>

      <div className={`spinner-container ${window.clientConfig.isPro ? "isPro" : ""}`}>
        <div className={`triple-spinner ${window.clientConfig.isPro ? "isPro" : ""}`}>
          <div className={`triple-spinner2 ${window.clientConfig.isPro ? "isPro" : ""}`} />
        </div>

        {this.props.images && this.props.images.length > 0 && <ImageView image={this.props.images[0]} />}
      </div>
      <div className={`loader-text-container ${window.clientConfig.isPro ? "isPro" : ""}`} hidden={this.props.hideText}>
        {/*<p className="loader-tip" dangerouslySetInnerHTML={{__html: i18n.t("processing_tip", {item: this.state.textIndex + 1, total: this.texts.length})}} />*/}
        <p dangerouslySetInnerHTML={{__html: i18n.t(this.texts[this.state.textIndex]) || ""}} />
      </div>

      {banner}

      <LoadingGenderSelectContainer
        direction='column'
        align="center"
        gap={8}
        isPro={window.clientConfig.isPro}
        style={{visibility: this.props.faceCount === 1 ? "visible" : "hidden"}}>
        <p>{i18n.t("processing_footer_text")}</p>
        <FlexWrap justify="center" gap={16}>
          <LoadingGenderButtonStyled
            disabled={!this.props.gender}
            active={this.props.gender === genders.female}
            onClick={() => this.props.onGenderSelect(genders.female)}>
            {i18n.t("processing_female_button")}
            <TouchEffect />
          </LoadingGenderButtonStyled>
          <LoadingGenderButtonStyled
            disabled={!this.props.gender}
            active={this.props.gender === genders.male}
            onClick={() => this.props.onGenderSelect(genders.male)}>
            {i18n.t("processing_male_button")}
            <TouchEffect />
          </LoadingGenderButtonStyled>
        </FlexWrap>
      </LoadingGenderSelectContainer>
    </main>;
  }
}

export default LoadingView;

function ImageView({image}) {
  const styles = {};

  if (image.rotation && image.rotation !== 0) {
    styles.transform = `rotate(${image.rotation}deg)`;
  }

  return <div className="triple-spinner-avatars">
    <div className={`triple-spinner-avatar-1 ${window.clientConfig.isPro ? "isPro" : ""}`}>
      <img src={image.url} style={styles} alt="." />
    </div>
  </div>;
}

function ArrowIcon() {
  return <svg viewBox="0 0 83 19">
    <defs>
      <clipPath id="5jf2cbmdwa">
        <path d="M1242 0v2208H0V0h1242z" />
      </clipPath>
    </defs>
    <g clipPath="url(#5jf2cbmdwa)" transform="translate(-703 -1605)">
      <path d="m766.5 1605 19 9.5-19 9.5v-8h-63v-3h63v-8z" />
    </g>
  </svg>
}

function ArrowRocket() {
  return <svg viewBox="0 0 210 210">
    <defs>
      <clipPath id="fzy9ekf0aa">
        <path d="M1242 0v2208H0V0h1242z" />
      </clipPath>
      <clipPath id="olqa0fz37b">
        <path d="M66.805 6.112c11.021 11.021 5.826 34.08-5.195 45.102C50.59 62.234 0 72.917 0 72.917l.013-.06c.414-1.948 10.89-50.75 21.69-61.55C32.724.284 55.783-4.91 66.805 6.111z" />
      </clipPath>
      <clipPath id="5z6yqva8bc">
        <path d="M52.555 4.806c8.67 8.67 4.579 26.813-4.091 35.483C39.794 48.959 0 57.36 0 57.36S8.402 17.567 17.072 8.898c8.67-8.67 26.813-12.761 35.483-4.092z" />
      </clipPath>
      <clipPath id="l4tgq2k12d">
        <path d="M40.081 3.664c6.616 6.609 3.497 20.451-3.119 27.068C30.354 37.342 0 43.75 0 43.75S6.408 13.393 13.016 6.783c6.609-6.609 20.45-9.729 27.065-3.12z" />
      </clipPath>
      <clipPath id="33f475zyye">
        <path d="M26.722 2.446c4.41 4.41 2.327 13.635-2.074 18.044C20.238 24.9 0 29.167 0 29.167s1.19-5.63 2.96-11.599l.224-.747.23-.749.236-.747C5.119 10.723 6.887 6.314 8.677 4.52c4.41-4.41 13.637-6.484 18.045-2.075z" />
      </clipPath>
      <clipPath id="cmxrd5nahf">
        <path d="M76.8.007 36.05 63.001 0 76.806C16.728 21.042 11.399.008 76.806.008L76.8.007z" />
      </clipPath>
      <clipPath id="smmztf6rvg">
        <path d="M76.798.005c0 65.402-21.034 60.073-76.798 76.8l13.805-36.05L76.798.006z" />
      </clipPath>
      <clipPath id="99j8pa1xxh">
        <path d="M49.583 0C26.392 25.7 34.071 39.703 42.59 46.435 31.524 50.143 17.467 53.167.008 58.333l13.791-35.519z" />
      </clipPath>
      <clipPath id="zrf6juzjhi">
        <path d="M75.833 0 35.9 62.024.008 75.834c3.715-12.462 6.337-23.177 8.897-32.326C28.841 59.304 49.816 32.193 75.833 0z" />
      </clipPath>
      <clipPath id="vz1kzi7cpj">
        <path d="M74.46 29.891C108.82-2.202 164.86-.036 167.088.064l.069.003s2.916 57.63-29.823 92.7c-32.748 35.06-102.45 74.455-102.45 74.455L0 132.338S39.393 62.632 74.46 29.891z" />
      </clipPath>
      <clipPath id="seo6fpaqak">
        <path d="M134.167 0C20.017 72.38 4.465 130.992 45.654 159.027c-6.6 3.957-10.645 6.25-10.645 6.25L0 130.224s39.536-70.048 74.731-102.95C92.24 10.9 115.354 3.405 134.167 0z" />
      </clipPath>
      <clipPath id="obefi3jqll">
        <path d="M47.238 0c2.09.009 3.25.067 3.25.067s1.213 23.946-6.641 50.481L0 6.709C19.901.819 38.35.028 46.33.001z" />
      </clipPath>
      <clipPath id="u818qy0rpm">
        <path d="M47.3 0c2.095.009 3.256.067 3.256.067-48.292 6.424-24.644 32.994-6.048 48.407-.196.689-.391 1.385-.595 2.082L.012 6.707l.784-.23C20.42.797 38.508.027 46.393.001z" />
      </clipPath>
      <clipPath id="zjmhrowsfn">
        <path d="M7.546 7.546c10.061-10.061 26.374-10.061 36.436 0 10.061 10.062 10.061 26.374 0 36.436-10.062 10.061-26.375 10.061-36.436 0-10.061-10.062-10.061-26.374 0-36.436z" />
      </clipPath>
      <clipPath id="69pkbyi1po">
        <path d="M24.792 0c13.692 0 24.791 11.1 24.791 24.792 0 13.692-11.099 24.791-24.791 24.791S0 38.483 0 24.792C0 11.099 11.1 0 24.792 0z" />
      </clipPath>
      <clipPath id="tyed4paj1p">
        <path d="M6.55 6.55c8.732-8.733 22.89-8.733 31.623 0 8.732 8.732 8.732 22.89 0 31.623-8.733 8.732-22.89 8.732-31.624 0-8.732-8.733-8.732-22.89 0-31.624z" />
      </clipPath>
      <clipPath id="zp6nsm2gdq">
        <path d="M5.695 5.695c7.594-7.593 19.905-7.593 27.499 0 7.593 7.594 7.593 19.905 0 27.499-7.594 7.593-19.905 7.593-27.499 0-7.593-7.594-7.593-19.905 0-27.499z" />
      </clipPath>
      <clipPath id="2otzsgop9r">
        <path d="M5.268 5.268c7.024-7.024 18.412-7.024 25.436 0 7.024 7.024 7.024 18.412 0 25.436-7.024 7.024-18.412 7.024-25.436 0-7.024-7.024-7.024-18.412 0-25.436z" />
      </clipPath>
      <clipPath id="sdfw3mdegs">
        <path d="M18.38 0c6.01 0 11.324 2.894 14.676 7.35L7.349 33.055C2.894 29.704 0 24.39 0 18.38c.008-.229.031-.45.04-.67L17.717.03C17.94.024 18.16 0 18.381 0z" />
      </clipPath>
      <clipPath id="ingqkmngxt">
        <path d="m24.051.008.196.497a18.434 18.434 0 0 1 1.03 4.21L4.71 25.278A18.327 18.327 0 0 1 0 24.05L24.051.008z" />
      </clipPath>
      <clipPath id="0axjn352tu">
        <path d="M1.944.922a1.022 1.022 0 1 1 0 2.045 1.022 1.022 0 0 1 0-2.045z" />
      </clipPath>
      <clipPath id="krry9ge91v">
        <path d="M2.665 1.223a1.022 1.022 0 0 1 0 1.442 1.022 1.022 0 0 1-1.441 0 1.022 1.022 0 0 1 0-1.442 1.022 1.022 0 0 1 1.441 0z" />
      </clipPath>
      <clipPath id="hexgdswl3w">
        <path d="M2.665 1.223a1.022 1.022 0 0 1 0 1.442 1.022 1.022 0 0 1-1.442 0 1.022 1.022 0 0 1 0-1.442 1.022 1.022 0 0 1 1.442 0z" />
      </clipPath>
      <clipPath id="o0m2hc0lyx">
        <path d="M2.665 1.223a1.022 1.022 0 0 1 0 1.442 1.022 1.022 0 0 1-1.442 0 1.022 1.022 0 0 1 0-1.442 1.022 1.022 0 0 1 1.442 0z" />
      </clipPath>
      <clipPath id="h3ghyq2q8y">
        <path d="M2.665 1.223a1.022 1.022 0 0 1 0 1.442 1.022 1.022 0 0 1-1.442 0 1.022 1.022 0 0 1 0-1.442 1.022 1.022 0 0 1 1.442 0z" />
      </clipPath>
      <clipPath id="2loo3dmomz">
        <path d="M1.944.922a1.022 1.022 0 1 1 0 2.045 1.022 1.022 0 0 1 0-2.045z" />
      </clipPath>
      <clipPath id="xm2a34x9cA">
        <path d="M2.665 1.223a1.022 1.022 0 0 1 0 1.442 1.022 1.022 0 0 1-1.441 0 1.022 1.022 0 0 1 0-1.442 1.022 1.022 0 0 1 1.441 0z" />
      </clipPath>
      <clipPath id="p9amsxu29B">
        <path d="M1.944.922a1.022 1.022 0 1 1 .001 2.044 1.022 1.022 0 0 1 0-2.044z" />
      </clipPath>
      <clipPath id="ukfe13omtC">
        <path d="M2.742 1.147a1.123 1.123 0 0 1 0 1.595 1.123 1.123 0 0 1-1.595 0 1.123 1.123 0 0 1 0-1.595 1.123 1.123 0 0 1 1.595 0z" />
      </clipPath>
      <clipPath id="z9r4chtxuD">
        <path d="M2.742 1.147a1.124 1.124 0 0 1 0 1.595 1.124 1.124 0 0 1-1.595 0 1.124 1.124 0 0 1 0-1.595 1.124 1.124 0 0 1 1.595 0z" />
      </clipPath>
      <clipPath id="nx2cgjziwE">
        <path d="M2.742 1.147a1.124 1.124 0 0 1 0 1.595 1.124 1.124 0 0 1-1.595 0 1.124 1.124 0 0 1 0-1.595 1.124 1.124 0 0 1 1.595 0z" />
      </clipPath>
      <clipPath id="7hvm7g8cqF">
        <path d="M2.742 1.147a1.124 1.124 0 0 1 0 1.595 1.124 1.124 0 0 1-1.595 0 1.124 1.124 0 0 1 0-1.595 1.124 1.124 0 0 1 1.595 0z" />
      </clipPath>
      <clipPath id="w8oahipd9G">
        <path d="M2.742 1.147a1.123 1.123 0 0 1 0 1.595 1.123 1.123 0 0 1-1.595 0 1.123 1.123 0 0 1 0-1.595 1.123 1.123 0 0 1 1.595 0z" />
      </clipPath>
      <clipPath id="ck4rsvpnmH">
        <path d="M2.742 1.147a1.123 1.123 0 0 1 0 1.595 1.123 1.123 0 0 1-1.595 0 1.123 1.123 0 0 1 0-1.595 1.123 1.123 0 0 1 1.595 0z" />
      </clipPath>
      <clipPath id="w73fyahqmI">
        <path d="M78.75.097 0 78.75s18.328-26.272 46.061-53.974C73.795-2.926 78.75.097 78.75.097z" />
      </clipPath>
      <clipPath id="nhvt9tvw6J">
        <path d="M78.658.009c.149.309 2.187 5.778-24.684 32.68C26.272 60.422 0 78.75 0 78.75L78.658.009z" />
      </clipPath>
      <clipPath id="dsy30zsk8K">
        <path d="M4.797 0S5.783 14.747 0 40.833c0 0 1.367-17.312 4.797-40.833z" />
      </clipPath>
    </defs>
    <g clipPath="url(#fzy9ekf0aa)" transform="translate(-516 -992)">
      <g clipPath="url(#olqa0fz37b)" transform="translate(516 1129.083)">
        <path fill="#ED572F" d="M0 0h72.917v72.917H0V0z" />
      </g>
      <g clipPath="url(#5z6yqva8bc)" transform="translate(529.611 1131.028)">
        <path fill="#EA902F" d="M0 0h57.361v57.361H0V0z" />
      </g>
      <g clipPath="url(#l4tgq2k12d)" transform="translate(540.306 1133.944)">
        <path fill="#F3BD30" d="M0 0h43.75v43.75H0V0z" />
      </g>
      <g clipPath="url(#33f475zyye)" transform="translate(551.972 1136.861)">
        <path fill="#FFE269" d="M0 0h29.167v29.167H0V0z" />
      </g>
      <g clipPath="url(#cmxrd5nahf)" transform="translate(521.833 1062.972)">
        <path fill="#EA3453" d="M0 0h76.806v76.806H0V0z" />
      </g>
      <g clipPath="url(#smmztf6rvg)" transform="translate(578.222 1119.361)">
        <path fill="#EA3453" d="M0 0h76.806v76.806H0V0z" />
      </g>
      <g clipPath="url(#99j8pa1xxh)" transform="translate(579.194 1136.861)">
        <path fill="#AA2B46" d="M0 0h49.583v58.333H0V0z" />
      </g>
      <g clipPath="url(#zrf6juzjhi)" transform="translate(522.806 1062)">
        <path fill="#AA2B46" d="M0 0h75.833v75.833H0V0z" />
      </g>
      <g clipPath="url(#vz1kzi7cpj)" transform="translate(558.778 992)">
        <path fill="#FFF" d="M0 0h167.222v167.222H0V0z" />
      </g>
      <g clipPath="url(#seo6fpaqak)" transform="translate(558.778 993.944)">
        <path fill="#DAD5E2" d="M0 0h134.167v165.278H0V0z" />
      </g>
      <g clipPath="url(#obefi3jqll)" transform="translate(675.444 992)">
        <path fill="#EA3453" d="M0 0h50.556v50.556H0V0z" />
      </g>
      <g clipPath="url(#u818qy0rpm)" transform="translate(675.444 992)">
        <path fill="#AA2B46" d="M0 0h50.556v50.556H0V0z" />
      </g>
      <g clipPath="url(#zjmhrowsfn)" transform="translate(635.583 1027.972)">
        <path fill="#E0DEDC" d="M0 0h51.528v51.528H0V0z" />
      </g>
      <g clipPath="url(#69pkbyi1po)" transform="translate(636.556 1028.944)">
        <path fill="#AA2B46" d="M0 0h49.583v49.583H0V0z" />
      </g>
      <g clipPath="url(#tyed4paj1p)" transform="translate(639.472 1031.861)">
        <path fill="#EA3453" d="M0 0h44.722v44.722H0V0z" />
      </g>
      <g clipPath="url(#zp6nsm2gdq)" transform="translate(642.389 1034.778)">
        <path fill="#384460" d="M0 0h38.889v38.889H0V0z" />
      </g>
      <g clipPath="url(#2otzsgop9r)" transform="translate(643.361 1035.75)">
        <path fill="#89BBE8" d="M0 0h35.972v35.972H0V0z" />
      </g>
      <g clipPath="url(#sdfw3mdegs)" transform="translate(643.361 1035.75)">
        <path fill="#BBE2FF" d="M0 0h33.056v33.056H0V0z" />
      </g>
      <g clipPath="url(#ingqkmngxt)" transform="translate(656 1046.444)">
        <path fill="#BBE2FF" d="M0 0h25.278v25.278H0V0z" />
      </g>
      <g clipPath="url(#0axjn352tu)" transform="translate(672.528 999.778)">
        <path fill="#B3B0B5" d="M.922.922h2.045v2.045H.922V.922z" />
      </g>
      <g clipPath="url(#krry9ge91v)" transform="translate(678.361 1005.611)">
        <path fill="#B3B0B5" d="M.926.926h2.037v2.037H.926V.926z" />
      </g>
      <g clipPath="url(#hexgdswl3w)" transform="translate(685.167 1012.417)">
        <path fill="#B3B0B5" d="M.926.926h2.037v2.037H.926V.926z" />
      </g>
      <g clipPath="url(#o0m2hc0lyx)" transform="translate(690.028 1017.278)">
        <path fill="#B3B0B5" d="M.926.926h2.037v2.037H.926V.926z" />
      </g>
      <g clipPath="url(#h3ghyq2q8y)" transform="translate(695.861 1022.139)">
        <path fill="#B3B0B5" d="M.926.926h2.037v2.037H.926V.926z" />
      </g>
      <g clipPath="url(#2loo3dmomz)" transform="translate(700.722 1027.972)">
        <path fill="#B3B0B5" d="M.922.922h2.045v2.045H.922V.922z" />
      </g>
      <g clipPath="url(#xm2a34x9cA)" transform="translate(706.556 1034.778)">
        <path fill="#B3B0B5" d="M.926.926h2.037v2.037H.926V.926z" />
      </g>
      <g clipPath="url(#p9amsxu29B)" transform="translate(712.389 1039.639)">
        <path fill="#B3B0B5" d="M.922.922h2.045v2.045H.922V.922z" />
      </g>
      <g clipPath="url(#ukfe13omtC)" transform="translate(559.75 1123.25)">
        <path fill="#B3B0B5" d="M.815.815h2.259v2.259H.815V.815z" />
      </g>
      <g clipPath="url(#z9r4chtxuD)" transform="translate(566.556 1129.083)">
        <path fill="#B3B0B5" d="M.815.815h2.258v2.259H.815V.815z" />
      </g>
      <g clipPath="url(#nx2cgjziwE)" transform="translate(573.361 1135.889)">
        <path fill="#B3B0B5" d="M.815.815h2.258v2.259H.815V.815z" />
      </g>
      <g clipPath="url(#7hvm7g8cqF)" transform="translate(578.222 1140.75)">
        <path fill="#B3B0B5" d="M.815.815h2.259v2.258H.815V.815z" />
      </g>
      <g clipPath="url(#w8oahipd9G)" transform="translate(585.028 1147.556)">
        <path fill="#B3B0B5" d="M.815.815h2.259v2.259H.815V.815z" />
      </g>
      <g clipPath="url(#ck4rsvpnmH)" transform="translate(591.833 1154.361)">
        <path fill="#B3B0B5" d="M.815.815h2.259v2.259H.815V.815z" />
      </g>
      <g clipPath="url(#w73fyahqmI)" transform="translate(550.028 1089.222)">
        <path fill="#EA3453" d="M0 0h78.75v78.75H0V0z" />
      </g>
      <g clipPath="url(#nhvt9tvw6J)" transform="translate(550.028 1089.222)">
        <path fill="#AA2B46" d="M0 0h78.75v78.75H0V0z" />
      </g>
      <g clipPath="url(#dsy30zsk8K)" transform="translate(719.194 995.889)">
        <path fill="#FFF" d="M0 0h4.861v40.833H0V0z" />
      </g>
    </g>
  </svg>
}
