import styled from "styled-components";

export const ParentStyled = styled.main`
  height: 100vh;
  overflow: auto;
  padding-bottom: 0;

  @media all and (min-width: 992px) and (orientation: landscape) {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 40px;
  }
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 540px;
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  margin: 0 auto;

  @media all and (min-width: 992px) and (orientation: landscape) {
    flex-grow: 1;
  }
`;

export const SliderContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: -16px;
  margin-bottom: 16px;
  margin-top: 16px;
  width: calc(100% + 32px);
  height: calc(100vh - 145px);

  .swiper {
    height: 100%;
    width: 100%;
  }
`;

export const SliderSlidesCountStyled = styled.div`
  margin-top: -30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  padding: 0;
  margin-bottom: 16px;
`;

export const SliderSlidesCountValueStyled = styled.p`
  font-size: 12px;
  font-weight: 300;
  line-height: 1.67;
  text-align: center;
  color: rgba(34, 34, 34, 0.7);
`;

export const ImageContextStyled = styled.div`
  &.disabled {
    opacity: 0.5;
  }
`;

export const ImageContextInputStyled = styled.input`
  appearance: none;
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
  left: -9999px;
  z-index: -10;

  &:checked + label {
    color: #ff62a5;

    &::after {
      border-color: #ff62a5;
      background-color: #ff62a5;
    }

    &::before {
      content: "";
      display: block;
      width: 7px;
      height: 12px;
      position: absolute;
      left: 6px;
      top: 1px;
      transform: rotate(45deg);
      box-sizing: border-box;
      border: 2px solid #D5EAFF;
      border-top: none;
      border-left: none;
      z-index: 10;
    }
  }
`;

export const ImageContextLabelStyled = styled.label`
  position: relative;
  font-size: 14px;
  color: rgb(34 34 34 / 70%);
  padding-left: 26px;

  &::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid #ff62a5;
  }
`;

export const BtnsGroupStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const ButtonStyled = styled.button`
  font-size: 16px;
  letter-spacing: 0.32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: none;
  position: relative;
  height: 48px;
  max-width: 47%;
  width: 100%;
  margin: 0;
  overflow: hidden;

  .touch-effect {
    border-radius: 100px;
  }

  &[disabled] {
    opacity: 0.5;

    .touch-effect {
        display: none;
    }
  }

  ${props => props.animate && `
    margin-left: 16px;

    svg {
      width: 26px;
      fill: #000;
      animation: shake2 800ms infinite ease-out;
      margin-left: 10px;
    }

    @keyframes shake2 {
      0% {
        transform: translateX(-5px);
      }
      50% {
        transform: translateX(5px);
      }

      100% {
        transform: translateX(-5px);
      }
    }
  `}

  ${props => props.full && `
    font-weight: bold;
    color: #000;
    background: #ff62a5;

    &[disabled] {
      color: #ff62a5;
      color: #000;
      background: #ff62a5;

    }
  `}

  ${props => props.activeCreativeIsPaywall && `
    background: #fff066; 
  `}

  ${props => props.transparent && `
    color: #ff62a5;
    border: 1px solid #ff62a5;
    background-color: transparent;

    &[disabled] {
      color: #ff62a5;
      border: 1px solid #ff62a5;
      background-color: transparent;
    }
  `}
`;

export const SpinnerStyled = styled.span`
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-top-color: transparent;
  animation: rotate 1.5s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`

export const ButtonChooseContainerStyled = styled.div`
  button {
    margin-top: 16px;
    font-size: 14px;
    font-weight: bold;
    color: rgb(34 34 34 / 70%);
    height: auto;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    border-radius: 0;
    padding: 4px 0;
  }
`;
