import React from "react";
import AppContext from "../contexts/AppContext";
import i18n from "../i18n";

const defaultPrice = "$0.99";

export default class PurchaseBannerView extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isOnScreen) {
      if (!this.shownEventIsLogged) {
        this.shownEventIsLogged = true;
      }
    } else {
      this.shownEventIsLogged = false;
    }
  }

  render() {
    if (this.props.hidden) {
      return <React.Fragment />;
    }

    const skuData = this.context.skus[this.props.sku] || {};
    const price = (skuData.price || defaultPrice).replace(/\s+/, "\u00A0");

    return <div className="subscribe-banner">
      <h2 dangerouslySetInnerHTML={{__html: i18n.t("purchase_banner__text")}} />
      <p dangerouslySetInnerHTML={{__html: i18n.t("purchase_banner__subtext", {price})}} />
    </div>;
  }
}

PurchaseBannerView.contextType = AppContext;