import React from "react";
import i18n from "../../i18n";
import AppContext from "../../contexts/AppContext";
import BannerView from "../../components/BannerView/BannerView";

export default class SubscribeProcessingBannerV1View extends React.Component {
  componentDidMount() {

  }

  render() {
    return <BannerView full={this.props.full}>
      {this.props.children}
    </BannerView>;
  }
}

SubscribeProcessingBannerV1View.contextType = AppContext;
