export const genderKeys = {
  male: "male",
  female: "female",
};

export const stylizationKeys = {
  glamour: "glamour",
  doll: "doll",
};

export const hairStylizationKeys = {
  no: "no",
  yes: "yes",
};

export const extraKeys = {
  version: "version",
  gender: "gender",
  detectedGender: "detected_gender",
  stylization: "stylization",
  strength: "strength",
  hairStylization: "hair_stylization",
};

