function defaultHandler(res) {
  if (res.ok) {
    return res.json();
  } else {
    const error = new Error("ResponseError");
    error.name = "ResponseError";
    error.response = res.clone();

    throw error;
  }
}

function networkErrorHandler(err) {
  const error = new Error("Network error");
  error.name = "NetworkError";
  error.parentError = err;

  throw error;
}

function defaultErrorHandler(err) {
  throw err;
}
function getFullPath(path = "", basePath) {
  const url = new URL((basePath || window.appConfig.paths.processingsCounter) + path);

  for (let key in window.clientConfig.webviewParams) {
    url.searchParams.append(key, window.clientConfig.webviewParams[key]);
  }

  return url.toString();
}

function addProcessing(processingId) {
  return fetch(getFullPath(""), {
    method: "POST",
    headers: {
      "Accept": "application/json",
    },
    body: JSON.stringify({
      action: "add",
      processing_id: processingId,
      project: window.appConfig.project.name,
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

function getCount() {
  return fetch(getFullPath(""), {
    method: "POST",
    headers: {
      "Accept": "application/json",
    },
    body: JSON.stringify({
      action: "count",
      project: window.appConfig.project.name,
    }),
  })
    .catch(networkErrorHandler)
    .then(defaultHandler)
    .catch(defaultErrorHandler);
}

export default {
  getCount,
  addProcessing,
};
