import {isWebviewApp} from "./config.utils";
import {webviewAppIds} from "./webview";

export function getSubscriptionDefaultSkuData(sku, trial = undefined) {
  return {
    title: sku,
    description: sku,
    price: "$4.99",
    period: "P1W",
    trial,
  };
}

export function getInAppDefaultSkuData(sku, extra) {
  return {
    sku: sku,
    title: sku,
    description: sku,
    price: "$0.99",
    ...extra,
  };
}

export function getSubscriptionSku(isTrial = false) {
  const subscriptionSkus = {
    photolabIos: "pro_subscr_weekly_4",
    photolabIosNoTrial: "pro_subscr_weekly_4_nt",
    photolabAndroid: "week_3",
    photolabAndroidNoTrial: "week_3_nt",

    toonmeIos: "pro_features_tnmweek_4",
    toonmeIosNoTrial: "pro_features_tnmweek_4_nt",
    toonmeAndroid: "week_3",
    toonmeAndroidNoTrial: "week_3_nt",

    nppIos: "weekly_sub_4",
    nppIosNoTrial: "weekly_sub_4_nt",
    nppAndroid: "week_2",
    nppAndroidNoTrial: "week_2_nt",
  };

  function choice(android, androidNoTrial, ios, iosNoTrial) {
    return window.clientConfig.isWebviewAndroid
      ? (isTrial ? android : androidNoTrial)
      : (isTrial ? ios : iosNoTrial);
  }

  if (isWebviewApp(webviewAppIds.toonme)) {
    return choice(
      subscriptionSkus.toonmeAndroid,
      subscriptionSkus.toonmeAndroidNoTrial,
      subscriptionSkus.toonmeIos,
      subscriptionSkus.toonmeIosNoTrial,
    );
  } else if (isWebviewApp(webviewAppIds.newprofilepic)) {
    return choice(
      subscriptionSkus.nppAndroid,
      subscriptionSkus.nppAndroidNoTrial,
      subscriptionSkus.nppIos,
      subscriptionSkus.nppIosNoTrial,
    );
  } else {
    return choice(
      subscriptionSkus.photolabAndroid,
      subscriptionSkus.photolabAndroidNoTrial,
      subscriptionSkus.photolabIos,
      subscriptionSkus.photolabIosNoTrial,
    );
  }
}

export function getInAppSku() {
  const inAppSkus = {
    toonme: "tm_makeover_1",
    photolab: "pl_makeover_1",
  };

  if (isWebviewApp(webviewAppIds.toonme)) {
    return inAppSkus.toonme;
  } else {
    return inAppSkus.photolab;
  }
}
