import React from "react";
import clientStorage from "../../utils/client-storage";
import routes from "../../routes";
import {goToProcessing} from "../../utils/url";
import {canShowNativeAds, showNativeAds} from "../../utils/native-ads";
import Loader from "../../components/Loader/Loader";

export default class StartPage extends React.Component {

  componentDidMount() {
    const files = clientStorage.getLatestSelectedImages();
    if (files.isEmpty()) {
      this.props.history.replace(routes.INDEX);
      return;
    }

    clientStorage.setLatestSelectedImages([]);
    goToProcessing(this.props.history, files);

    if (canShowNativeAds()) {
      showNativeAds();
    }
  }

  render() {
    return <Loader />;
  }
}
