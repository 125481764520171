import styled from "styled-components";
import FlexWrap from "../../components/FlexWrap/FlexWrap";

export const IndexPageStyled = styled.main`
    padding-top: 0;
    position: relative;

    .container {
      padding-top: 99px;
      position: relative;
      max-width: 100%;
    }

    .content-container {
      position: relative;
      justify-content: flex-end;
      z-index: 1;
      padding-bottom: 12px;

      @media (max-width: 780px) {
        padding-bottom: 36px;
      }

      > p {
        margin-bottom: 48px;
        max-width: 352px;
      }

      @media (min-width: 1025px) {
        >button {
          margin-bottom: 48px;
        }
      }
    }
`;

export const IndexPageProccessingContainerStyled = styled(FlexWrap)`
  --width-banner: 0;
  position: ${props => props.relative ? "relative" : "absolute"};
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  z-index: -1;
  //filter: blur(3px);
  //transition: filter .2s ease;

  &::after {
    display: ${props => props.fadeOff ? "none" : "block"};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(to top, rgba(213, 234, 255, 1) 10%, rgba(213, 234, 255, 0) 60%, rgba(213, 234, 255, 0) 85%, rgba(213, 234, 255, 1) 94%);
    z-index: 10;

    @media (max-width: 1024px) {
      background: linear-gradient(to top, rgba(213, 234, 255, 1) 10%, rgba(213, 234, 255, 0) 55%);
    }
    @media (orientation: landscape) {
      background: linear-gradient(to top, rgba(213, 234, 255, 1) 10%, rgba(213, 234, 255, 0) 75%, rgba(213, 234, 255, 0) 85%, rgba(213, 234, 255, 1) 94%);
    }
  }

  ${props => props.banner && `
    z-index: 10;

    & > div {
      &:first-child {
        width: 100%;
  
        -webkit-animation: slideOriginalPercent 600s linear infinite;
        animation: slideOriginalPercent 600s linear infinite;

        @media (max-width: 1024px) {
          -webkit-animation: slideOriginalPercent 300s linear infinite;
          animation: slideOriginalPercent 300s linear infinite;
        }
      }

      &:last-child {
        width: 70%;

        -webkit-animation: slideProcessedPercent 600s linear infinite;
        animation: slideProcessedPercent 600s linear infinite;

        @media (max-width: 1024px) {
          -webkit-animation: slideProcessedPercent 300s linear infinite;
          animation: slideProcessedPercent 300s linear infinite;
        }
      }
    }

    &::after {
      height: calc(100% + 1px);
      background: linear-gradient(to top, rgba(180, 215, 254, 1) 10%, rgba(180, 215, 254, 0) 60%, rgba(180, 215, 254, 0) 85%, rgba(180, 215, 254, 1) 94%);
      z-index: 10;

      @media (max-width: 1024px) {
        background: linear-gradient(to top, rgba(180, 215, 254, 1) 10%, rgba(180, 215, 254, 0) 55%);
      }
      @media (orientation: landscape) {
        background: linear-gradient(to top, rgba(180, 215, 254, 1) 10%, rgba(180, 215, 254, 0) 75%, rgba(180, 215, 254, 0) 85%, rgba(180, 215, 254, 1) 94%);
      }
    }
  `}

  @keyframes slideOriginal {
    0% {
      background-position: 0;
    }

    100% {
      background-position: 4030vw;
    }
  }

  @keyframes slideProcessed {
    0% {
      background-position: -30vw;
    }

    100% {
      background-position: 4000vw;
    }
  }

  @keyframes slideOriginalPercent {
    0% {
      background-position: 0;
    }

    100% {
      background-position: ${props => props.width * 40 + props.width * 0.3}px;
    }
  }

  @keyframes slideProcessedPercent {
    0% {
      background-position: ${props => -props.width * 0.3}px;
    }

    100% {
      background-position: ${props => props.width * 40}px;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  &.load_webview,
  &.load_desktop {
    div {
      &:first-child {
        -webkit-animation: slideOriginal 600s linear infinite;
        animation: slideOriginal 600s linear infinite;

        @media (max-width: 1024px) {
          -webkit-animation: slideOriginal 300s linear infinite;
          animation: slideOriginal 300s linear infinite;
        }
      }

      &:last-child {
        -webkit-animation: slideProcessed 600s linear infinite;
        animation: slideProcessed 600s linear infinite;

        @media (max-width: 1024px) {
          -webkit-animation: slideProcessed 300s linear infinite;
          animation: slideProcessed 300s linear infinite;
        }
      }
    }
  }
`;

export const IndexPageProccessingOriginalStyled = styled.div`
  width: 100vw;
  overflow: hidden;
  height: 100%;
  z-index: 0;
  background-image: url(${props => props.bannerOriginal ? props.bannerOriginal : "/assets/images/banner/originals.webp"});
  background-position: 0 0;
  background-repeat: no-repeat;
  background-repeat: repeat-x;
  background-size: auto 100%;
  // animation: slideOriginal 600s linear infinite;

  // @media (max-width: 1024px) {
  //   animation: slideOriginal 300s linear infinite;
  // }
`;

export const IndexPageProccessingFinalStyled = styled(IndexPageProccessingOriginalStyled)`
  position: absolute;
  width: 70vw;
  right: 0;
  border-left: ${props => props.line ? "none" : "1px solid #222"};
  z-index: 1;
  background-image: url(${props => props.bannerResult ? props.bannerResult : "/assets/images/banner/results.webp"});
  background-position: -30vw 0;

  ${props => props.line && `
    overflow: visible;
    
    &::before {
      content: "";
      display: block;
      height: calc(100% + 24px);
      width: 2px;
      margin-top: -12px;
      background: #fff066;
    }
  `}

  &.banner {
    background-position: -${props => props.width * 0.3}px 0;
  }
  // animation: slideProcessed 600s linear infinite;

  // @media (max-width: 1024px) {
  //   animation: slideProcessed 300s linear infinite;
  // }
`;

export const AppStoreLinksContainerStyled = styled(FlexWrap)`
  //@media (max-width: 1024px) {
  //  display: none;
  //}
`;

export const IndexPageFooterLinksContainerStyled = styled(FlexWrap)`
  margin-top: 18px;
`;

export const AppStoreLinkStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(17, 17, 17);
  border-radius: 12px;
  height: 48px;
  width: 100%;
  max-width: 174px;
  margin-left: 12px;

  &:first-child {
    margin: 0;
  }

  svg {
    margin-right: 12px;
  }

  p {
    font-size: 10px;
    line-height: 18px;
    letter-spacing: -0.1px;
    margin: 0;
    text-align: left;
    color: rgb(17, 17, 17);

    &.store {
      font-weight: bold;
      font-size: 16px;
      letter-spacing: -0.16px;
    }
  }
`;

export const IndexPageFooterLinkStyled = styled.a`
  font-size: 12px;
  line-height: 1.5;
  color: rgba(34, 34, 34, 0.45);
  margin-left: 48px;

  &:first-child {
    margin-left: -16px;
  }
`;
