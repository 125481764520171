import React from "react";
import {pwAssetUrl} from "../../utils/etc";
import {ImageAnimationStyled} from "./ImageAnimation.style";

const images = [
    "/assets/images/animation/1.webp",
    "/assets/images/animation/2.webp",
    "/assets/images/animation/3.webp",
    "/assets/images/animation/4.webp",
    "/assets/images/animation/5.webp",
];

export default class ImageAnimation extends React.Component {
    render() {
        return (
            <ImageAnimationStyled>
                {images.map((image, i) => (
                    <img src={image} alt="" key={i} />
                ))}
            </ImageAnimationStyled>
        )
    }
}